import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import DataTitleComponent from './DataTitleComponent';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles((theme) => ({
	imgBox: {
		background: theme.palette.primary.main,
		borderRadius: '50%',
		width: 52,
		height: 52,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '2px solid white',
		padding: theme.spacing(0.5),
	},
	img: {
		width: 45,
		height: 45,
		objectFit: 'cover',
	}
}))

const WeatherComponent = ({ loading, data }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<DataTitleComponent
			loading={loading}
			title={t('weather.title')}
			name={`${data?.name} °C`}
		>
			<Box className={classes.imgBox}>
				<LazyLoadImage
					effect="blur"
					className={classes.img}
					src={`https://openweathermap.org/img/wn/${data?.icon}.png`}
					alt={t('weather.title')}
				/>
			</Box>
		</DataTitleComponent>
	)
}


WeatherComponent.propTypes = {
	data: PropTypes.object,
	loading: PropTypes.bool,
}
export default React.memo(WeatherComponent);