import React from 'react'
import { Backdrop, makeStyles, } from '@material-ui/core';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
	},
}))

const BackdropComponent = ({ children, open, className = '' }) => {
	const classes = useStyles();

	return (
		<>
			<Backdrop open={open} className={classNames([classes.backdrop, className])} >
				{children}
			</Backdrop>
		</>
	)
}

export default React.memo(BackdropComponent);