export const AIR_QUALITY = 'http://api.gios.gov.pl/pjp-api/rest/aqindex/getIndex/987';
export const API_URL = process.env.NODE_ENV === 'production' ? '' : '';
export const DATE_FORMAT = 'DD MMMM YYYY';
export const BIP_LOSS = 'http://bip.um.szczecin.pl/chapter_11090.asp'
export const DISABLED_LOSS = 'http://bip.um.szczecin.pl/chapter_11254.asp'
export const HEARING_LOSS = 'http://eurzad.szczecin.pl/chapter_51179.asp';

export const ACCESS_TEXT = "accessText";
export const ACCESS_BIGGEST_TEXT = "accessBiggestText";
export const ACCESS_BIG_TEXT = "accessBigText";
export const ACCESS_CONTARST = "accessContrast";

export const CURRENT_VERSION = "currentVersion";

export const FLOATING_GARDEN = 'https://www.szczecin.eu/pl/marka-floating-garden'


export const FACEBOOK_LINK = 'https://www.facebook.com/szczecin.eu';
export const YOUTUBE_LINK = 'https://www.youtube.com/user/umszczecin';
export const TWITTER_LINK = 'https://twitter.com/miasto_szczecin';

export const DECLARATION_LINK = 'https://www.szczecin.eu/pl/deklaracja-dostepnosci'
export const POLICY_LINK = 'https://www.szczecin.eu/pl/polityka-prywatnosci'

export const RECAPTCHA_ACTION = 'contact_form'

// indexDB

export const INDEX_DB_NAME = 'application'
export const INDEX_DB_VERSION = 1;
export const INDEX_DB_MAIN_MODEL_NAME = 'main'
export const INDEX_DB_PAGES_MODEL_NAME = 'pages'
export const INDEX_DB_WATER_NAME = 'water'
export const INDEX_DB_NOISE_NAME = 'noise'
export const INDEX_DB_EDUCATION_NAME = 'education'
export const INDEX_DB_ECO_PROMOTION_NAME = 'eco_promotion'
export const INDEX_DB_INSTITUTIONS_NAME = 'institutions'
export const INDEX_DB_WASTES_NAME = 'wastes'
export const INDEX_DB_AIR_NAME = 'air'
export const INDEX_DB_LAND_NAME = 'land'
export const INDEX_DB_STRATEGY_NAME = 'strategy'
export const INDEX_DB_GREEN_NAME = 'green'
export const INDEX_DB_ANIMALS_NAME = 'animals'
export const INDEX_DB_ECO_CALENDAR_NAME = 'eco_calendar'