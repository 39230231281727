import * as React from "react"

function GreenCitySecondBackgroundSvg(props) {
	return (
		<svg
			viewBox="0 0 800 250"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit={2}
			{...props}
		>
			<path
				d="M230.525 234.778c-18.483-17.685-29.674-34.965-34.02-51.049-12.42-46.003 31.28-82.205 120.588-90.354 59.6-5.442 127.004-4.19 197.246 4.286a1095.358 1095.358 0 0135.497 4.872c25.476 3.909 51.901 8.873 76.117 17.375 37.415 13.153 63.53 32.446 93.772 49.216 30.717 17.05 67.344 32.084 106.103 43.882l-.24.935c-39.068-11.881-75.982-27.026-106.994-44.232-30.442-16.911-56.427-36.058-93.218-48.973-23.808-8.363-50.004-13.301-75.388-17.194a1108.394 1108.394 0 00-35.367-4.852c-70.006-8.45-137.178-9.697-196.578-4.274-88.688 8.104-131.905 43.992-119.616 89.54 4.302 15.933 15.392 33.054 33.715 50.59 8.973 8.581 17.54 17.12 25.324 25.454l-2.071-.251c-7.656-8.178-16.07-16.554-24.87-24.971m-35.664 8.732c-3.47-12.872-11.256-26.251-23.896-39.7C136.14 166.693 69.687 133.157 27.633 96.22c-.43-.375-.842-.741-1.256-1.117-8.49-7.642-13.392-14.913-15.19-21.553-6.812-25.384 31.686-41.363 87.137-34.717a251.7 251.7 0 0125.966 4.574c19.29 4.446 40.138 7.874 59.758 10.242 16.612 2 32.334 3.248 45.477 3.715 56.43 2.022 99.77-9.894 147.925-16.86 68.064-9.842 148.651-9.62 232.592.502 7.924.957 15.873 2.002 23.846 3.135 39.936 5.672 81.477 13.796 119.259 27.496 37.048 13.454 66.992 30.859 96.447 48.606l-.282 1.1c-29.74-17.928-59.722-35.453-96.776-48.89-37.388-13.557-78.666-21.647-118.453-27.299a1192.977 1192.977 0 00-23.761-3.12c-83.663-10.093-163.982-10.303-231.8-.507-47.994 6.924-91.453 18.914-148.417 16.89-13.253-.473-29.076-1.725-45.79-3.746-19.723-2.379-40.692-5.82-60.173-10.306a243.53 243.53 0 00-25.54-4.507c-54.564-6.585-92.052 9.23-85.486 33.926 1.745 6.468 6.524 13.566 14.849 21.054.399.365.817.733 1.23 1.095l.005.005c41.695 36.67 108.224 70.236 143.454 107.702 12.767 13.592 20.616 27.102 24.136 40.103a63.013 63.013 0 011.758 9.149l-1.925-.235a62.84 62.84 0 00-1.761-9.147"
				fill="#96bf1e"
			/>
		</svg>
	)
}

export default GreenCitySecondBackgroundSvg
