import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ARTICLE_CSS, IMG_CSS } from '../constants/csses';
import SkeletonComponent from './skeletons/SkeletonComponent';
import loadable from '@loadable/component'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const TitleComponent = loadable(() => import('./TitleComponent'))

const useStyles = (fullWidth) => makeStyles((theme) => ({
	body: {
		marginTop: theme.spacing(2),
		...ARTICLE_CSS(theme, fullWidth)
	},
	box: {
		marginTop: theme.spacing(7),
		marginBottom: theme.spacing(7),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(5),
			marginBottom: theme.spacing(5),
		}
	},
	titleBox: {
		maxWidth: fullWidth ? '100%' : theme.custom.paragraphWidth,
		margin: 'auto',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
		},
	},
	titleSkeleton: {
		width: fullWidth ? '100%' : theme.custom.paragraphWidth,
		height: 50,
		margin: 'auto',
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: theme.spacing(3),
		}
	},
	descSkeleton: {
		width: fullWidth ? '100%' : theme.custom.paragraphWidth,
		margin: 'auto',
		height: 400,
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		}
	},
	imgBox: {
		width: '100%',
		'& span': {
			...IMG_CSS(theme),
		}
	},
}))

const TextSectionComponent = ({ startIcon, fullWidth, src, loading, line, title, subtitle, body, variant, variantSubtitle }) => {
	const classes = useStyles(fullWidth)();
	return (
		<Box className={classes.box}>
			{loading
				? <>
					<SkeletonComponent
						className={classes.titleSkeleton}
					/>
					<SkeletonComponent
						className={classes.descSkeleton}
					/>
				</>
				: <>
					<TitleComponent
						startIcon={startIcon}
						line={line}
						variant={variant}
						variantSubtitle={variantSubtitle}
						className={classes.titleBox}
						title={title}
						subtitle={subtitle}
					/>
					{src
						? <Box className={classes.imgBox}>
							<LazyLoadImage
								className={classes.img}
								src={src}
								alt={title}
								effect='blur'
							/>
						</Box>
						: null
					}
					{
						body && (
							<Typography
								className={classes.body}
								dangerouslySetInnerHTML={{ __html: body }}
							/>
						)
					}
				</>

			}

		</Box>
	)
}


TextSectionComponent.propTypes = {
	title: PropTypes.string,
	fullWidth: PropTypes.bool,
	line: PropTypes.bool,
	loading: PropTypes.bool,
	subtitle: PropTypes.string,
	body: PropTypes.string,
}
export default React.memo(TextSectionComponent);