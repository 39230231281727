import * as React from "react"

function BipSvg(props) {
	return (
		<svg
			id="BipSvg_Warstwa_1"
			data-name="Warstwa 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 151.59 64.86"
			{...props}
		>
			<defs>
				<style>{".BipSvg_cls-1{fill:#c00418}"}</style>
			</defs>
			<title>{"bip"}</title>
			<path
				className="BipSvg_cls-1"
				d="M0 13.6l35.34 35.88V13.6H0zM132 14.89a19.44 19.44 0 00-19.43 19.42v27.12a4.75 4.75 0 009.49 0V50.91a19.39 19.39 0 109.94-36m0 29.35a9.93 9.93 0 119.93-9.93 9.94 9.94 0 01-9.93 9.91M68.43 14.91a19.19 19.19 0 00-9.94 2.83V6.05a4.74 4.74 0 00-9.48 0v28.32a19.42 19.42 0 1019.42-19.46m0 29.36a9.94 9.94 0 119.94-9.93 10 10 0 01-9.94 9.93m36.5.48c-.64-.32-2.58-1.3-2.58-8.84V19.69a4.74 4.74 0 10-9.48 0v16.22c0 5.75.81 13.79 7.79 17.32a4.75 4.75 0 104.27-8.48m-7.32-33a5.22 5.22 0 10-5.21-5.2 5.22 5.22 0 005.21 5.21"
			/>
		</svg>
	)
}

export default BipSvg
