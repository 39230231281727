import React, { useCallback } from 'react'
import { Box, Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import EcoZoneBackgroundSvg from '../../../svgs/EcoZoneBackgroundSvg';
import EcoZoneLineTopBackgroundSvg from '../../../svgs/EcoZoneLineTopBackgroundSvg';

import CiepleMieszkanieSVG from '../../../imgs/cieple_mieszkanie.svg'

import TitleComponent from '../../../components/TitleComponent';
import SquareCardsSectionComponent from '../../../components/SquareCardsSectionComponent';
import preloadPages from '../../../functions/preloadPages';

const useStyles = makeStyles((theme) => ({
	ecoZonesSection: {
		position: 'relative',
		overflowX: 'clip',
		background: theme.palette.background.grayGradient,
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(5),
			paddingBottom: theme.spacing(5),
		}
	},
	ecoZoneBackground: {
		position: 'absolute',
		top: theme.spacing(5),
		height: '100vh',
		left: '-30vh',
		zIndex: 0,
	},
	ecoZoneLineTopBackground: {
		position: 'absolute',
		top: 0,
		width: '110vw',
		left: '-5vw',
		zIndex: 0,
	},
	ecoZoneLineBottomBackground: {
		position: 'absolute',
		bottom: 0,
		width: '100%',
		left: 0,
		zIndex: 0,
	},

	container: {
		position: 'relative',
		zIndex: 1,
	},

}))

const EcoZonesSectionComponent = ({ children, showBackgroundIcons, showTitle, loading, cards = [] }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const handlePreloadPages = useCallback(() => {
		preloadPages();
	}, [])

	return (
		<Box className={classes.ecoZonesSection} onMouseOver={handlePreloadPages}>
			<Container className={classes.container}>
				{children}
				{showTitle
					? <TitleComponent
						title={t('eco_zones_section.title')}
						subtitle={t('eco_zones_section.subtitle')}
					/>
					: null
				}
				<SquareCardsSectionComponent
					cards={[...cards, {
						src: CiepleMieszkanieSVG,
						title: "Ciepłe mieszkanie",
						external_link: true,
						to: "https://zielonemiasto.szczecin.eu/strona/cieple-mieszkanie"
					}]}
					loading={loading}
				/>
			</Container>
			{showBackgroundIcons
				? <>
					{/* <EcoZoneBackgroundSvg className={classes.ecoZoneBackground} /> */}
					<EcoZoneLineTopBackgroundSvg className={classes.ecoZoneLineTopBackground} />
				</>
				: null}

		</Box>
	)
}


EcoZonesSectionComponent.propTypes = {
	loading: PropTypes.bool,
	showBackgroundIcons: PropTypes.bool,
	showTitle: PropTypes.bool,
	cards: PropTypes.array,
}
export default React.memo(EcoZonesSectionComponent);