import { useTheme } from "@material-ui/core"
import * as React from "react"

function NotFoundSvg(props) {
	const theme = useTheme();
	return (
		<svg
			viewBox="0 0 757 257"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			{...props}
		>
			<path
				d="M385.716 103.94l56.9-40.64h8.128v8.128l-40.64 56.9M385.716 152.709l-56.9 40.64h-8.128v-8.128l40.64-56.9"
				fill="none"
				stroke={theme.palette.primary.main}
				strokeWidth={3}
			/>
			<circle
				cx={24.384}
				cy={24.384}
				r={24.384}
				fill="none"
				stroke={theme.palette.primary.main}
				strokeWidth={3}
				transform="translate(361.332 103.94)"
			/>
			<path
				d="M381.652 42.98h8.128M381.652 213.669h8.128M296.307 128.325h8.128M467 128.325h8.128M381.652 128.325h8.128M214.1 206.744h-38.727v48.406h-51.5v-48.406H1.5v-15.1L145.171 1.504h30.206v159.93H214.1v45.31zm-90.23-45.31V94.441l-49.955 66.993h49.955zM755.36 206.744h-38.73v48.406h-51.5v-48.406H542.76v-15.1L686.43 1.504h30.2v159.93h38.73v45.31zm-90.23-45.31V94.441l-49.96 66.993h49.96z"
				fill="none"
				stroke={theme.palette.primary.main}
				strokeWidth={3}
			/>
			<circle
				cx={126.825}
				cy={126.825}
				r={126.825}
				fill="none"
				stroke={theme.palette.primary.main}
				strokeWidth={3}
				transform="translate(258.891 1.5)"
			/>
		</svg>
	)
}

export default NotFoundSvg
