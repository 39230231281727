import * as React from "react"

function CityPagesSvg(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={44.885}
			height={42.56}
			viewBox="0 0 44.885 42.56"
			{...props}
		>
			<defs>
				<style>
					{
						".CityPagesSvg_a{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px}"
					}
				</style>
			</defs>
			<path
				className="CityPagesSvg_a"
				d="M22.441 11.075h14.047l6.353 6.753-6.348 6.888H22.441M21.766 16.207H8.124L2.046 9.59l6.078-6.483h13.642M22.306 0v40.115M18.119 41.06h8.644"
			/>
		</svg>
	)
}

export default CityPagesSvg
