import * as React from "react"

function DisabledPersonSvg(props) {
	return (
		<svg
			viewBox="0 0 20 25"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M14.715 18.5a6.878 6.878 0 01-3.4 4.6 6.982 6.982 0 01-3.331.845C4.153 23.945 1 20.793 1 16.961A6.992 6.992 0 014.515 10.9"
				fill="none"
				stroke="#fff"
				strokeWidth={2}
			/>
			<circle
				cx={3.3}
				cy={3.3}
				r={3.3}
				fill="none"
				stroke="#fff"
				strokeWidth={2}
				transform="translate(4.715 1)"
			/>
			<path
				d="M8.015 7.6v7.1h10.1v6.5"
				fill="none"
				stroke="#fff"
				strokeWidth={2}
			/>
		</svg>
	)
}

export default DisabledPersonSvg
