import React from 'react'
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../../functions';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = (white, primary) => makeStyles((theme) => ({
    btn: {
        display: 'flex',
        borderRadius: 50,
        alignItems: 'center',
        width: 'fit-content',
        transition: theme.custom.transition,
        background: theme.palette.button.gradient,
        backgroundSize: '200% 100%',
        cursor: 'pointer',
        '&:hover': {
            backgroundPosition: '100% 0',
            '&.arrow': {
                background: theme.palette.primary.main,
            }
        },
        padding: 5,
        paddingLeft: theme.spacing(2),
    },
    backBtn: {
        flexDirection: 'row-reverse',
    },
    title: {
        color: theme.palette.text.white,
        marginBottom: 0,
        lineHeight: 1,
        marginRight: theme.spacing(1),
        wordBreak: 'break-word',
        padding: theme.spacing(1),
    },
    arrow: {
        background: '#FFFFFF17',
        borderRadius: '50%',
        lineHeight: 0,
        padding: theme.spacing(1),
    },
    icon: {
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.white,
    },
    iconBack: {
        transform: 'rotate(180deg)'
    }
}))

const ButtonComponent = ({ back, title, onClick = () => { }, white, primary, className = '' }) => {
    const classes = useStyles(white, primary)();

    return (
        <section className={classNames([classes.btn, className, back ? classes.backBtn : ''])} onClick={onClick}>
            <Typography className={classes.title} variant='button'>{title}</Typography>
            <div className={classNames([classes.arrow, 'arrow'])}>
                <ArrowForwardIosIcon className={classNames([classes.icon, back ? classes.iconBack : ''])} />
           
            
            </div>
        </section>
    )
}


ButtonComponent.propTypes = {
    title: PropTypes.string,
    back: PropTypes.bool,
    onClick: PropTypes.func,
}
export default React.memo(ButtonComponent);