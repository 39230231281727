import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATIC_API } from '../constants/api';
import { GET } from "../functions/superagentSending";

export const FETCH_STATIC_PAGE_REDUCER = createAsyncThunk(
	'StaticPageReducer/fetchStaticPage',
	async (slug) => {
		const response = await GET(`${STATIC_API}/${slug}`);
		return response.body.data
	}
)


export const StaticPageReducer = createSlice({
	name: 'StaticPageReducer',
	initialState: {
		data: {},
		loading: false,
		error: {},
	},
	reducers: {
	},
	extraReducers: {
		[FETCH_STATIC_PAGE_REDUCER.pending]: (state) => {
			state.loading = true;
		},
		[FETCH_STATIC_PAGE_REDUCER.fulfilled]: (state, action) => {
			state.error = {};
			state.data = action.payload
			state.loading = false;
		},
		[FETCH_STATIC_PAGE_REDUCER.rejected]: (state, action) => {
			state.data = {};
			state.loading = false;
			state.error = action;
		}
	}
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = StaticPageReducer.actions

export default StaticPageReducer.reducer