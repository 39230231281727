export const CARD_HOVER_ANIM = {
	backgroundColor: '#50535799 !important',
	backdropFilter: 'blur(10px)',
}


export const LINE_CSS = (theme) => (
	{
		height: 3,
		width: 100,
		border: 'none',
		background: theme.palette.primary.main,
		marginLeft: 0,
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	}
)

export const IMG_CSS = (theme) => (
	{
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		width: '100%',
		height: 'auto !important',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100% !important',
		},
	}
)
export const PAPER_HOVER = {
	img: {
		transition: 'all 0.3s ease-in-out !important',
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		transform: 'scale(1)'
	},
	srcPaper: {
		padding: 0,
		overflow: 'hidden',
		'& span': {
			width: '100%',
			height: '100%',
		},
		'&:hover': {
			'& img': {
				transform: 'scale(1.1)'
			}
		}
	}
}

export const activeSmallCard = (theme) => ({
	boxShadow: '0px 0px 50px #00000045',
	background: theme.palette.background.white,
	[theme.breakpoints.down('sm')]: {
		boxShadow: '2px 6px 10px #00000010',
	},
})

export const mobileScrollCont = (theme) => ({
	[theme.breakpoints.down('sm')]: {
		flexWrap: 'nowrap',
		overflowX: 'scroll',
		marginBottom: theme.spacing(3),
		paddingBottom: theme.spacing(4),
	},
})

export const mobileScrollItem = (theme) => ({
	[theme.breakpoints.down('sm')]: {
		paddingTop: 0,
		paddingBottom: 0,
		width: '45% !important',
		minWidth: '45%',
		flexBasis: '45%  !important',
		boxShadow: 'none !important',
	},
	[theme.breakpoints.down('xs')]: {
		width: '90% !important',
		minWidth: '90%',
		flexBasis: '90%  !important',
	},
})

export const ARTICLE_CSS = (theme, fullWidth) => ({
	marginBottom: theme.spacing(3),
	fontFamily: theme.typography.fontFamily,
	overflowWrap: 'break-word',
	'& *': {
		fontSize: theme.typography.body1.fontSize,
		maxWidth: '100%',
		height: 'auto',
		wordBreak: 'break-word',
	},

	'& p, h1, h2, h3, h4, h5, h6, ul, ol': {
		maxWidth: fullWidth ? '100%' : theme.custom.paragraphWidth,
		margin: 'auto',
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
		},
		'& ol, ul': {
			maxWidth: '100%',
			marginBottom: theme.spacing(2),
		}
	},
	'& [data-oembed-url]': {
		'& > div': {
			maxWidth: '100% !important',
			'& > div': {
				paddingBottom: '0 !important',
				height: 'auto !important',
				'& iframe': {
					position: 'relative !important',
					minHeight: 450,
					[theme.breakpoints.down('sm')]: {
						minHeight: 350,
					},
				}
			}
		}
	},
	// '& iframe': {
	//     minHeight: 600,
	// },
	'& p': {
		...theme.typography.body1,
	},
	'& hr': {
		background: theme.palette.secondary.main,
		border: `0.75px solid ${theme.palette.secondary.main}`,
	},
	'& h1': {
		...theme.typography.h1,
		'& *': {
			...theme.typography.h1
		}
	},
	'& h2': {
		...theme.typography.h2,
		'& *': {
			...theme.typography.h2
		}
	},
	'& h3': {
		...theme.typography.h3,
		'& *': {
			...theme.typography.h3
		}
	},
	'& h4': {
		...theme.typography.h4,
		'& *': {
			...theme.typography.h4
		}
	},
	'& .default': {
		width: '100%',
		maxWidth: '100%',
	},
	'& img': {
		...IMG_CSS(theme),
	},
	'& ol ol, ol ul': {
		fontSize: theme.typography.body1.fontSize,
		listStyle: 'none',
		padding: 0,
		'& li::before': {
			color: theme.palette.primary.main,
			content: "'•'",
			fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
			lineHeight: 1,
			margin: '0 10px 0 -20px',
		},
	},
	'& ul': {
		'& li': {
			marginLeft: 20,
		},
		listStyle: 'none',
		padding: 0,
		'& li::before': {
			color: theme.palette.primary.main,
			content: "'•'",
			fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
			lineHeight: 1,
			margin: '0 10px 0 -20px',
		},
	},
	'& ul ol': {
		fontSize: 14,
		marginLeft: 20,
		listStyle: 'none !important',
		counterReset: 'countMe2',
		'& li': {
			counterIncrement: 'countMe2',
			paddingLeft: '25px',
		},
		'& li::before': {
			color: theme.palette.primary.main,
			content: 'counter(countMe2) ". "',
			fontSize: `calc(${theme.typography.body1.fontSize} + 2px)`,
		},
	},
	'& ul, ol ul': {
		'& li::before': {
			fontWeight: 550,
			lineHeight: 24,
			marginLeft: '-10px',
		},
	},
	'& ol li::marker': {
		width: '1em',
		fontWeight: 900,
		color: theme.palette.primary.main,
		margin: '0 6px 0 -25px',
	},
	'& .custom-btn': {
		display: 'flex',
		borderRadius: 50,
		alignItems: 'center',
		width: 'fit-content',
		transition: theme.custom.transition,
		background: theme.palette.button.gradient,
		backgroundSize: '200% 100%',
		cursor: 'pointer',
		'& *': {
			color: theme.palette.text.white,
		},
		'&:hover': {
			backgroundPosition: '100% 0',
			'&.arrow': {
				background: theme.palette.primary.main,
			},
			'& *': {
				color: theme.palette.text.white,
			},
		},
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5),
		padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		}
	},
	'& .border': {
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		padding: '15px 40px',
		fontSize: theme.typography.h1.fontSize,
		borderRadius: 50,
		marginTop: 5,
		marginBottom: 5,
		textDecoration: 'none',
		textAlign: 'center',
		maxWidth: '100%',
		width: 'fit-content',
	},
	'& .border *': {
		fontSize: theme.typography.h1.fontSize,
	},
	'& a': {
		textDecoration: 'none',
		color: theme.palette.secondary.main,
		transition: 'all 0.2s ease-in',
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
	'& table': {
		border: 'none',
		width: '100%',
		margin: '20px auto',
		borderSpacing: 0,
	},
	'& td, th': {
		border: `1px solid ${theme.palette.gray.light} !important`,
		padding: '0 5px',
		// borderColor: theme.palette.gray.light,
		// textAlign: 'center',
		// [theme.breakpoints.down('sm')]: {
		//   display: 'block',
		//   width: '100% !important',
		// },
	},
	'& caption': {
		fontSize: theme.typography.body1.fontSize,
		fontStyle: 'italic',
		marginBottom: 10,
	},
	'& blockquote': {
		position: 'relative',
		padding: '10px 20px',
		margin: 0,
		background: theme.palette.background.gray,
		'&>p': {
			fontSize: '18px !important',
			fontWeight: '400 !important',
			fontStyle: 'italic',
		},
		'&::after': {
			color: theme.palette.background.gray,
			fontSize: '12em',
			lineHeight: '0.65em',
			right: '20px',
			position: 'absolute',
			zIndex: 0,
			top: '-10px',
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			width: 4,
			height: '100%',
			display: 'block',
			top: 0,
			left: 0,
			background: theme.palette.primary.main,
		},
	},
})