import { createTheme } from '@material-ui/core/styles';


const textWhite = '#ffffff';
const primary = '#A2BD31';
const primaryGradient = 'linear-gradient(60deg, rgba(173, 197, 74, 1) 0%, rgba(94, 219, 176, 1) 100%)';
const grayMain = '#F7F7F8'
const grayGradient = `linear-gradient(0deg,#FFFFFF  0%, #E9EBF0 50%, ${grayMain}  100%)`;
const primaryBoxShadow = '0px 0px 18px #00000004';
const transition = 'all 0.3s ease-in-out'
const radius = 10;
const mobileBoxShadow = '2px 6px 10px #00000010';
const mobile = '@media (max-width: 959px)';
const lessMobile = '@media (max-width: 450px)';
export const newTheme = () => {
    const theme = createTheme({
        '*': {
            lineHeight: 1.5,
            fontFamily: 'sofia-pro, sans-serif',
        },
        typography: {
            fontFamily: 'sofia-pro, sans-serif',
            'button': {
                fontSize: 14,
                [lessMobile]: {
                    fontSize: 12,
                },
            },
            'body1': {
                fontSize: 14,
                marginBottom: 5,
                [lessMobile]: {
                    fontSize: 12,
                },
            },
            'body2': {
                fontWeight: 'bold',
                fontSize: 16,
                [lessMobile]: {
                    fontSize: 14,
                },
            },
            'h6': {},
            'h5': {
                fontSize: 16,
                [lessMobile]: {
                    fontSize: 14,
                },
            },
            'h4': {
                fontSize: 18,
                fontWeight: 'bold',
                [lessMobile]: {
                    fontSize: 16,
                },
            },
            'h3': {
                fontSize: 20,
                fontWeight: 'bold',
                marginBottom: 10,
                [lessMobile]: {
                    fontSize: 16,
                },
            },
            'h2': {
                fontSize: 36,
                marginBottom: 40,
                fontWeight: 'bold',
                [lessMobile]: {
                    fontSize: 24,
                },
            },
            'h1': {},
        },
        palette: {
            airQuality: {
                '-1': '#BFBFBF',
                '0': '#75CB60',
                '1': '#BADB48',
                '2': '#F9DA4C',
                '3': '#D78530',
                '4': '#D12D1F',
                '5': '#8B1A11',
            },
            floatingGarden: {
                border: '#BFBFBF',
                contrastText: '#9B9B9B',
                light: '#00AEEF',
                main: '#30318C',
                secondary: '#8DC63F'
            },
            header: {
                main: '#0296D8',
                dark: '#0075a9',
                darkest: '#07203B',
            },
            button: {
                gradient: 'linear-gradient(90deg, #23497d 0%, #5edbaf 50%, #23497d 100%)'
            },
            text: {
                primary: '#454545',
                white: '#ffffff'
            },
            primary: {
                main:  primary,
                dark: primary,
                darkest: primary,
            },
            secondary: {
                main: '#A2BD31',
            },
            gray: {
                light: '#e6e6e6',
                border: "#707070",
                main: grayMain,
            },
            background: {
                default: '#ffffff',
                gray: '#F3F4F6',
                white: '#ffffff',
                darkBlue: "#232D7D",
                darknessBlue: "#171e52",
                primaryGradient: primaryGradient,
                grayGradient: grayGradient,
            },
            burger: {
                disable: '#000000',
                active: '#C73D3D',
            }
        },
        overrides: {
            MuiPaper: {
                elevation1: {
                    background: '#ffffff',
                    borderRadius: 30,
                    transition: transition,
                    overflow: 'hidden',
                    '&.active': {
                        background: primaryGradient,
                        '& img': {
                            transition: transition,
                            filter: 'brightness(0) invert(1)',
                        },
                        '& *': {
                            transition: transition,
                            color: textWhite,
                        },
                        '& svg': {
                            '& rect': {
                                fill: textWhite,
                            },
                            '& path': {
                                fill: textWhite,
                            },
                        },
                    },
                    '&:hover': {
                        background: primaryGradient,
                        '& img': {
                            filter: 'brightness(0) invert(1)',
                        },
                        '& *': {
                            color: textWhite,
                        },
                        '& svg': {
                            '& rect': {
                                fill: textWhite,
                            },
                            '& path': {
                                fill: textWhite,
                            },
                        },
                    },
                },
                elevation2: {
                    background: '#ffffff',
                    borderRadius: 20,
                    boxShadow: '2px 6px 50px #00000010',
                    transition: transition,
                    padding: 60, 
                    [mobile]: {
                        padding: 40,
                        boxShadow: mobileBoxShadow ,
                    },
                    [lessMobile]: {
                        padding: 20,
                    },
                },
                elevation3: {
                    transition: transition,
                    background: grayMain,
                    boxShadow: 'none',
                    padding: 0,
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    [mobile]: {
                        boxShadow: mobileBoxShadow,
                    },
                },
                elevation4: {
                    background: '#ffffff',
                    borderRadius: 20,
                    boxShadow: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    transition: transition,
                    border: '1px solid #E2E2E2',
                    padding: 60,
                    [mobile]: {
                        padding: 40, 
                    },
                    [lessMobile]: {
                        padding: 20,
                    },
                },
            }
        },
        custom: {
            header: {
                maxWidth: 1500,
                margin: 'auto',
            },
            paragraphWidth: '70%',
            smallImg: 200,
            defaultImg: 250,
            radius: radius,
            transition: transition,
            boxShadow: '0px -10px 10px #c1c0c0',
            primaryBoxShadow: primaryBoxShadow,
        },
        props: {
            MuiContainer: {
                // maxWidth: "md",
            },
  
        },
    })
    return {
        ...theme,
    }
}

const MainTheme = newTheme();
export default MainTheme