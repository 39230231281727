import React from 'react'
import { Box, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ButtonComponent from './form/ButtonComponent';
import { classNames } from '../functions';
import CustomBtnComponent from './form/CustomBtnComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTranslation } from 'react-i18next';
import { PAPER_HOVER } from '../constants/csses';
import LinkComponent from './LinkComponent';

const useStyles = makeStyles((theme) => ({
	icon: {
		objectFit: 'contain',
		width: 75,
		height: 75,
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			width: 35,
			height: 35,
			marginBottom: theme.spacing(1),
		},
	},
	paper: {
		height: '100%',

	},
	grid: {
		padding: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			// width: `calc(100vw - ${theme.spacing(3)}px)`
			minWidth: '80%'
		},
		[theme.breakpoints.down('xs')]: {
			// width: `calc(100vw - ${theme.spacing(3)}px)`
			minWidth: '95%'
		},
	},
	desc: {
		marginTop: theme.spacing(3),
	},
	btn: {
		marginTop: theme.spacing(5),
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(3),
		},
	},
	primaryPaper: {
		background: theme.palette.background.primaryGradient,
		color: theme.palette.text.white
	},
	primaryIcon: {
		filter: 'brightness(0) invert(1)'
	},
	outlinedBtn: {
		marginTop: theme.spacing(5),
		color: theme.palette.text.white,
		borderColor: theme.palette.text.white,
		[theme.breakpoints.down('xs')]: {
			marginTop: theme.spacing(3),
		},
	},
	...PAPER_HOVER,
}))

const LargeCardComponent = ({ elevation = 2, to = '/', src, external_link, iconSrc, title, description, primary, btnTitle }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Grid md={6} xs={12} item className={classes.grid}>
			<Paper
				elevation={elevation}
				className={classNames([classes.paper, src ? classes.srcPaper : '', primary ? classes.primaryPaper : ''])}
			>
				{src
					? <>
						<LazyLoadImage
							effect="blur"
							className={classes.img}
							src={src}
							alt={title}
						/>

					</>
					: <>
						{iconSrc ? <img
							src={iconSrc}
							alt={title}
							className={classNames([classes.icon, primary ? classes.primaryIcon : ''])}
						/>
							: null
						}
						<Box>
							<Typography variant={iconSrc ? 'h3' : 'h2'} component='h3'>{title}</Typography>
							<Typography
								className={classes.desc}
								dangerouslySetInnerHTML={{ __html: description }}
							/>
						</Box>
						{to
							? <LinkComponent to={to} external_link={external_link}>
								{
									primary
										? <CustomBtnComponent
											variant='outlined'
											className={classes.outlinedBtn}
										>
											{btnTitle || t('btns.read_more')}
										</CustomBtnComponent>
										: <ButtonComponent
											title={btnTitle || t('btns.read_more')}
											className={classes.btn}
										/>
								}
							</LinkComponent>
							: null
						}

					</>
				}
			</Paper>
		</Grid>
	)
}


LargeCardComponent.propTypes = {
	src: PropTypes.string,
	btnTitle: PropTypes.string,
	description: PropTypes.string,
	elevation: PropTypes.number,
	title: PropTypes.string,
	iconSrc: PropTypes.string,
}
export default React.memo(LargeCardComponent);