import React, { useCallback } from 'react'
import {  makeStyles, } from '@material-ui/core';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import LoaderAnimation from '../animations/loader.json';
import BackdropComponent from './BackdropComponent';

const useStyles = makeStyles((theme) => ({

    loader: {
        width: 500,
    }
}))

const LoaderComponent = ({ open, className = '' }) => {
    const classes = useStyles();
    const loaderCont = useCallback(node => {
        if (node !== null) {
            lottie.loadAnimation({
                container: node,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: LoaderAnimation
            });
        }
    }, []);

    return (
        <>
            <BackdropComponent className={className} open={open}>
                <div className={classes.loader} ref={loaderCont}></div>
            </BackdropComponent>
        </>
    )
}


LoaderComponent.propTypes = {
    open: PropTypes.bool,
}
export default React.memo(LoaderComponent);