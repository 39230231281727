import { INDEX_DB_NAME, INDEX_DB_PAGES_MODEL_NAME, INDEX_DB_VERSION } from "../constants";

const hasModel = (db, modelName) => {
	let isHas = false;
	if (db?.objectStoreNames) {
		const arr = Object.keys(db.objectStoreNames);
		for (let i = 0; i < arr?.length; i++) {
			if (db.objectStoreNames[i] === modelName) {
				isHas = true;
				break;
			}
		}
	}
	return isHas;
}

export const pageOpenDb = (data) => {
	const indexes = [
		{
			name: 'contentSections',
			unique: false,
		},
		{
			name: 'defaultCards',
			unique: false,
		},
		{
			name: 'gallery',
			unique: false,
		},
		{
			name: 'intro',
			unique: false,
		},
		{
			name: 'points',
			unique: false,
		},
		{
			name: 'sectionsCards',
			unique: false,
		},
		{
			name: 'smallCards',
			unique: false,
		},
		{
			name: 'squareCards',
			unique: false,
		},
		{
			name: 'title',
			unique: false,
		},
		{
			name: 'slug',
			unique: true,
		},
	]
	openDB(INDEX_DB_NAME, INDEX_DB_VERSION, INDEX_DB_PAGES_MODEL_NAME, 'slug', indexes, data)

}
export const openDB = (name, version = 1, modelName, keyPath, indexes = [], data) => {
	// let db = false;
	const request = window.indexedDB.open(name, version)
	request.onerror = (e) => {
		console.log('error', e);
	}
	request.onsuccess = (e) => {
		const db = e.target.result;
		if (hasModel(db, modelName)) {
			// removeDataDB(db, modelName)
			// addDataDB(db, modelName, data)
			updateDataDB(db, modelName, data)
		}
	}
	request.onupgradeneeded = (e) => {
		const db = e.target.result;
		// create Model
		const objectStore = db.createObjectStore(modelName, { keyPath })
		// add Indexs
		indexes.forEach(({ name, unique }) => {
			objectStore.createIndex(name, name, { unique })
		})
		// add data
		for (let i in data) {
			objectStore.add(data[i]);
		}

	}

}

export const addDataDB = (db, modelName, data) => {
	const transaction = db.transaction([modelName], 'readwrite');
	const objectStore = transaction.objectStore(modelName);
	for (let i in data) {
		objectStore.add(data[i]);
	}
}

export const updateDataDB = (db, modelName, data) => {
	const transaction = db.transaction([modelName], 'readwrite');
	const objectStore = transaction.objectStore(modelName);
	for (let i in data) {
		objectStore.put(data[i]);
	}
}
export const updateItemDB = (name, modelName, data) => {
	const request = window.indexedDB.open(name)
	request.onerror = (e) => {
		console.log('error', e);
	}
	request.onsuccess = (e) => {
		const db = e.target.result;
		if (hasModel(db, modelName)) {
			const transaction = db.transaction([modelName], 'readwrite');
			const objectStore = transaction.objectStore(modelName);
			objectStore.put(data);
		}
	}
}

export const removeDataDB = (db, modelName) => {
	const request = db.transaction([modelName], 'readwrite').objectStore(modelName).clear();
	request.onsuccess = function (event) {
		// It's gone!
	};
	request.onerror = function (event) {
		// It's gone!
	};
}

export const getDataDB = (name, modelName, successData = () => { }) => {
	const request = window.indexedDB.open(name);
	request.onerror = (e) => {

	}
	request.onsuccess = (e) => {
		const db = e.target.result;
		if (hasModel(db, modelName)) {
			const transaction = db.transaction([modelName]);
			const objectStore = transaction.objectStore(modelName);
			const request = objectStore.getAll();
			request.onsuccess = (e) => {
				successData(e.target.result)
			}
			request.onerror = (e) => {
			}
		} else {
			successData([])
		}
	}
}
export const getDataItemDB = (name, modelName, key, successData = () => { }) => {
	const request = window.indexedDB.open(name);
	request.onerror = (e) => {

	}
	request.onsuccess = (e) => {
		const db = e.target.result;
		if (hasModel(db, modelName)) {
			const transaction = db.transaction([modelName]);
			const objectStore = transaction.objectStore(modelName);
			const request = objectStore.get(key);
			request.onsuccess = (e) => {
				successData(e.target.result)
			}
			request.onerror = (e) => {
			}
		} else {
			successData({})
		}
	}
}