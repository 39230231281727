import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Container,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useCallback } from 'react';
import ButtonComponent from './form/ButtonComponent';

const useStyles = makeStyles((theme) => ({
  cookie: {
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  mainBox: {
    position: 'fixed',
    zIndex: 999999,
    bottom: 0,
    background: theme.palette.background.white,
    left: 0,
    '-webkit-box-shadow': '0 0px 30px 0 rgb(0 0 0/7%)',
    boxShadow: '0 0px 30px 0 rgb(0 0 0/7%)',
    right: 0,
    visibility: 'hidden',
    transition: 'transform 0.4s ease-in-out, visibility 0.2s 0.4s ease-in',
    transform: 'translate(0, 150%)',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  activeCookie: {
    visibility: 'visible',
    transition: 'transform 0.4s ease-in-out',
    transform: 'translate(0, 0)',
  },
  btnBox: {
  minWidth: 170 + theme.spacing(2),
   marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      minWidth: 170,
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  }
}));

const CookiesComponent = (props) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['cookies-shown']);
  const [canShow, setCanShow] = useState(false);
  const { t } = useTranslation();
  const handleAccept = useCallback(() => {
    setCookie('cookies-shown', true, {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    });
  }, [setCookie]);

  useEffect(() => {
    const scrollCheck = () => {
      if (!canShow && window.pageYOffset >= 100 && !cookies['cookies-shown']) {
        setCanShow(true);
      }
    }
    window.addEventListener('scroll', scrollCheck);
    return (() => {
      window.removeEventListener('scroll', scrollCheck);
    })
    // eslint-disable-next-line 
  }, [cookies])
  return (
    <Box className={`${classes.mainBox} 
    ${!cookies['cookies-shown'] && canShow ? classes.activeCookie : ''
      }`}>

      <Container
        className={classes.cookie}
      >
        <Box className={classes.contentBox}>
          <Typography variant='h3'>
            {t('cookies.title')}
          </Typography>
          <Typography className={classes.content}>
            {t('cookies.content')}
          </Typography>
        </Box>
        <Box className={classes.btnBox}>
          <ButtonComponent
          onClick={handleAccept}
            title={t('btns.accept')}
            className={classes.btn}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default React.memo(CookiesComponent);
