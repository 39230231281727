import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { INDEX_DB_WASTES_NAME, } from '../constants';
import { WASTES_API } from '../constants/api';
import {  pageOpenDb, } from '../functions/indexDB';
import { GET } from "../functions/superagentSending";

export const FETCH_WASTES_PAGE_REDUCER = createAsyncThunk(
	'WastesPageReducer/fetchWastesPage',
	async (page) => {
		try {
			const response = await GET(WASTES_API);
			if (response?.body?.data)
				pageOpenDb([{ ...response.body.data, slug: INDEX_DB_WASTES_NAME }])
			return response.body
		} catch (error) {

			throw error?.response?.statusCode;
		}
	}
)


export const WastesPageReducer = createSlice({
	name: 'WastesPageReducer',
	initialState: {
		data: {},
		loading: false,
		error: {},
	},
	reducers: {
		FETCH_CACHE_WASTES_PAGE_REDUCER: (state, { payload }) => {
			state.data = payload
		}
	},
	extraReducers: {
		[FETCH_WASTES_PAGE_REDUCER.pending]: (state) => {
			state.loading = true;
			state.error = {};
		},
		[FETCH_WASTES_PAGE_REDUCER.fulfilled]:  (state, { payload }) => {
			state.data = payload.data;
			state.loading = false;
			state.error = {};
		},
		[FETCH_WASTES_PAGE_REDUCER.rejected]: (state, action) => {
			state.data = {};
			state.loading = false;
			state.error = action?.error;
		},
	}
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, FETCH_CACHE_WASTES_PAGE_REDUCER} = WastesPageReducer.actions

export default WastesPageReducer.reducer