import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Fab, makeStyles, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import HeaderComponent from './HeaderComponent';
import loadable from '@loadable/component'
import { useDispatch } from 'react-redux';
import { getDataDB } from '../functions/indexDB';
import { INDEX_DB_NAME, INDEX_DB_PAGES_MODEL_NAME } from '../constants';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NOT_FOUND_ROUTE } from '../constants/routes';
import CookiesComponent from './CookiesComponent';
import ArrowSvg from '../svgs/ArrowSvg';
import { useTranslation } from 'react-i18next';
import { classNames } from '../functions';

const FooterComponent = loadable(() => import('./FooterComponent'))

const useStyles = makeStyles((theme) => ({
    main: {
        overflowX: 'clip',
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
    },
    childrenBox: {
        flex: 1,
    },
    fab: {
        background: theme.palette.background.primaryGradient,
        position: 'fixed',
       
        right: theme.spacing(3),
    },
    increaseDistance: {
        bottom: theme.spacing(20),
    },
    bottom: {
        bottom: theme.spacing(2),
    },
    arrowIcon: {
        width: 30,
        height: 30,
        '& path': {
            fill: theme.palette.text.white,
        }
    },
    showTopBtn: {
        transition: theme.custom.transition,
        display: 'block',
        opacity: '1',
    },
    disableTopBtn: {
        transition: theme.custom.transition,
        display: 'none',
        opacity: '0',
    },
}))

const MainContainerComponent = ({ title, slug, disableSpace, disableCache, hiddenFetch, indexDbModelName, fetchCacheFun = () => { }, fetchFun = () => { }, children }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [showTopBtn, setShowTopBtn] = useState(false);
    const [changePosTopBtn, setChangePosTopBtn] = useState(false);
    const state = useSelector(state => state);
    const errorsArray = useMemo(() => Object.keys(state).filter((key) => state[key].error).map((key) => state[key].error), [state]);

    useEffect(() => {
        if (!hiddenFetch) {
            if (window.navigator.onLine || disableCache)
                dispatch(fetchFun(slug));
            else
                getDataDB(INDEX_DB_NAME, indexDbModelName || INDEX_DB_PAGES_MODEL_NAME, (data) => {
                    dispatch(fetchCacheFun(data?.length ? data[0] : {}))
                })
        }
        // eslint-disable-next-line
    }, [dispatch, fetchFun, hiddenFetch,])

    useEffect(() => {
        if (errorsArray?.length && errorsArray.find((item) => item?.error?.message ? item?.error?.message.includes('404') : false))
            history.push(NOT_FOUND_ROUTE)
        // eslint-disable-next-line
    }, [errorsArray])
    useEffect(() => {
        if (window.dataLayer && title !== '404') {
            window.dataLayer.push({
                event: 'Pageview',
                page: {
                    title
                }
            });
        }
    }, [title])

    const handleTop = useCallback(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        const scrollCheck = () => {
            const childrenBox = document.querySelector('#childrenBox');
            const newsletterCont = document.querySelector('body');
            if (childrenBox?.clientHeight) {
                setShowTopBtn(window.pageYOffset >= (childrenBox.clientHeight - window.screen.height))
            }
            if (newsletterCont?.clientHeight) {
                setChangePosTopBtn(window.pageYOffset >= (newsletterCont.clientHeight - window.screen.height))
            }
        }
        window.addEventListener('scroll', scrollCheck);
        return (() => {
            window.removeEventListener('scroll', scrollCheck);
        })
        // eslint-disable-next-line 
    }, [])
    return (
        <section className={classes.main}>
            <HeaderComponent />
            <Box className={classes.childrenBox} id='childrenBox'>
                {children}
            </Box>
            <CookiesComponent />
            <FooterComponent disableSpace={disableSpace} />
            <Tooltip title={t('top')} aria-label={t('top')} className={showTopBtn ? classes.showTopBtn : classes.disableTopBtn}>
                <Fab color="primary" className={
                    classNames([
                        classes.fab,
                        changePosTopBtn
                            ? classes.increaseDistance
                            : classes.bottom

                    ])
                } onClick={handleTop}>
                    <ArrowSvg className={classes.arrowIcon} />
                </Fab>
            </Tooltip>
        </section>
    )
}


MainContainerComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(MainContainerComponent);