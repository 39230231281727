import React  from 'react'
import {  makeStyles, } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataTitleComponent from './DataTitleComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LinkComponent from './LinkComponent';

const useStyles = (id) => makeStyles((theme) => ({

	name: {
		color: theme.palette.airQuality[`${id}`]
	},
	img: {
		width: 50,
		height: 50,
		objectFit: 'cover',
	},
}))

const AirQualityComponent = ({ data, loading }) => {
	const classes = useStyles(data?.id)();
	const {t} = useTranslation();
	return (
		<LinkComponent
			to={'http://powietrze.gios.gov.pl/pjp/current'}
			external_link={true}
		>
			<DataTitleComponent
				title={t('air_quality.title')}
				loading={loading}
				name={data?.name}
				className={classes.name}
			>
				<LazyLoadImage
					effect="blur"
					className={classes.img}
					src={data?.src}
					alt={data?.name}
				/>
			</DataTitleComponent>

		</LinkComponent>
	)
}


AirQualityComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(AirQualityComponent);
