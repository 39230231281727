import React from 'react'
import PropTypes from 'prop-types';


const PictureComponent = ({ src, className = '', alt }) => {
	
	return (
		<picture className={className}>
			<source srcSet={src.xl} media="(min-width: 1920px)" />
			<source srcSet={src.lg} media="(min-width: 1280px)" />
			<source srcSet={src.md} media="(min-width: 960px)" />
			<source srcSet={src.sm} media="(min-width: 600px)" />
			<source srcSet={src.xs} media="(min-width: 0px)" />
			<img src={src.lg} alt={alt} className={className} />
		</picture>
	)
}


PictureComponent.propTypes = {
	src: PropTypes.object,
	className: PropTypes.string,
	alt: PropTypes.string,
}
export default React.memo(PictureComponent);