import loadable from '@loadable/component'

const AirPage = loadable(() => import('../pages/airPage/AirPage'))
const AnimalsPage = loadable(() => import('../pages/animalsPage/AnimalsPage'))
const EcoPromotionPage = loadable(() => import('../pages/ecoPromotionPage/EcoPromotionPage'))
const EducationPage = loadable(() => import('../pages/educationPage/EducationPage'))
const GreenPage = loadable(() => import('../pages/greenPage/GreenPage'))
const InstitutionsPage = loadable(() => import('../pages/institutionsPage/InstitutionsPage'))
const LandPage = loadable(() => import('../pages/landPage/LandPage'))
const NoisePage = loadable(() => import('../pages/noisePage/NoisePage'))
const StaticPage = loadable(() => import('../pages/staticPage/StaticPage'))
const StrategyPage = loadable(() => import('../pages/strategyPage/StrategyPage'))
const WastesPage = loadable(() => import('../pages/wastesPage/WastesPage'))
const WaterPage = loadable(() => import('../pages/waterPage/WaterPage'))

const preloadPages =  () => {
    AirPage.preload()
    AnimalsPage.preload()
    EcoPromotionPage.preload()
    EducationPage.preload()
    GreenPage.preload()
    InstitutionsPage.preload()
    LandPage.preload()
    NoisePage.preload()
    StaticPage.preload()
    StrategyPage.preload()
    WastesPage.preload()
    WaterPage.preload()
}

export default preloadPages;