import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// MAP_ROUTE, STRATEGY_ROUTE
import { AIR_ROUTE, ANIMALS_ROUTE, CONFERENCE_ROUTE, CONTACT_ROUTE, ECO_PROMOTION_ROUTE, EDUCATION_ROUTE, GREEN_ROUTE, INSTITUTIONS_ROUTE, LAND_ROUTE, MAIN_ROUTE, NOISE_ROUTE, NOT_FOUND_ROUTE, SBO_ROUTE, STATIC_ROUTE, WASTES_ROUTE, WATER_ROUTE } from "./constants/routes";
import MainPage from "./pages/mainPage/MainPage";
import loadable from '@loadable/component'
import LoaderComponent from "./components/LoaderComponent";
import NotFoundPage from "./pages/notFoundPage/NotFoundPage";

const AirPage = loadable(() => import('./pages/airPage/AirPage'), {
  fallback: <LoaderComponent open />,
});
const AnimalsPage = loadable(() => import('./pages/animalsPage/AnimalsPage'), {
  fallback: <LoaderComponent open />,
});
const ConferencePage = loadable(() => import('./pages/conferencePage/ConferencePage'), {
  fallback: <LoaderComponent open />,
});
const EcoPromotionPage = loadable(() => import('./pages/ecoPromotionPage/EcoPromotionPage'), {
  fallback: <LoaderComponent open />,
});
const EducationPage = loadable(() => import('./pages/educationPage/EducationPage'), {
  fallback: <LoaderComponent open />,
});
const GreenPage = loadable(() => import('./pages/greenPage/GreenPage'), {
  fallback: <LoaderComponent open />,
});
const InstitutionsPage = loadable(() => import('./pages/institutionsPage/InstitutionsPage'), {
  fallback: <LoaderComponent open />,
});
const LandPage = loadable(() => import('./pages/landPage/LandPage'), {
  fallback: <LoaderComponent open />,
});
const NoisePage = loadable(() => import('./pages/noisePage/NoisePage'), {
  fallback: <LoaderComponent open />,
});
const SboPage = loadable(() => import('./pages/sboPage/SboPage'), {
  fallback: <LoaderComponent open />,
});
const StaticPage = loadable(() => import('./pages/staticPage/StaticPage'), {
  fallback: <LoaderComponent open />,
});
// const StrategyPage = loadable(() => import('./pages/strategyPage/StrategyPage'), {
//   fallback: <LoaderComponent open />,
// });
const WastesPage = loadable(() => import('./pages/wastesPage/WastesPage'), {
  fallback: <LoaderComponent open />,
});
const WaterPage = loadable(() => import('./pages/waterPage/WaterPage'), {
  fallback: <LoaderComponent open />,
});
const ContactPage = loadable(() => import('./pages/contactPage/ContactPage'), {
  fallback: <LoaderComponent open />,
});

const Root = (props) => {


  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={WATER_ROUTE} component={WaterPage} />
        <Route exact path={NOISE_ROUTE} component={NoisePage} />
        <Route exact path={CONFERENCE_ROUTE} component={ConferencePage} />
        <Route exact path={SBO_ROUTE} component={SboPage} />
        <Route exact path={EDUCATION_ROUTE} component={EducationPage} />
        <Route exact path={ECO_PROMOTION_ROUTE} component={EcoPromotionPage} />
        <Route exact path={INSTITUTIONS_ROUTE} component={InstitutionsPage} />
        <Route exact path={WASTES_ROUTE} component={WastesPage} />
        <Route exact path={AIR_ROUTE} component={AirPage} />
        <Route exact path={LAND_ROUTE} component={LandPage} />
        {/* <Route exact path={STRATEGY_ROUTE} component={StrategyPage} /> */}
        <Route exact path={ANIMALS_ROUTE} component={AnimalsPage} />
        <Route exact path={GREEN_ROUTE} component={GreenPage} />
        <Route exact path={`${STATIC_ROUTE}/:slug`} component={StaticPage} />
        <Route exact path={CONTACT_ROUTE} component={ContactPage} />
        <Route exact path={MAIN_ROUTE} component={MainPage} />
        <Route exact path={NOT_FOUND_ROUTE} component={NotFoundPage} />
        <Route path={'*'} component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default Root;
