import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles} from '@material-ui/core';
import MainMenuComponent from './MainMenuComponent';
import HeaderToolbarComponent from './HeaderToolbarComponent';

const useStyles = makeStyles((theme) => ({
    navBar: {
        boxShadow: '0 0px 25px 0 rgb(0 0 0 / 15%)',
        position: 'sticky',
        zIndex: 10,
        top: 0,
        left: 0,
    },
}))

const HeaderComponent = (props) => {
    const classes = useStyles();
    return (
        <section className={classes.navBar}>
            <HeaderToolbarComponent />
            <MainMenuComponent />
        </section>
    )
}


HeaderComponent.propTypes = {
    name: PropTypes.string,
}
export default React.memo(HeaderComponent);