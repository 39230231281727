export const CONFERENCE_ROUTE = '/konferencja'
export const SBO_ROUTE = '/zielone-sbo'
export const SBO_EXTERNAL_ROUTE = 'https://sbo.szczecin.eu'
export const MAIN_ROUTE = '/'
export const WATER_ROUTE = '/woda'
export const EDUCATION_ROUTE = '/edukacja'
export const ECO_PROMOTION_ROUTE = '/eko-akcje'
export const INSTITUTIONS_ROUTE = '/instytucje'
export const WASTES_ROUTE = '/odpady'
export const AIR_ROUTE = '/powietrze'
export const LAND_ROUTE = '/ziemia'
export const STRATEGY_ROUTE = '/strategia'
export const CONTACT_ROUTE = '/kontakt'
export const NOISE_ROUTE = '/halas'
export const GREEN_ROUTE = '/zielen'
export const ANIMALS_ROUTE = '/zwierzeta'
export const NEWS_ROUTE = 'https://wiadomosci.szczecin.eu/'
export const ECO_CALENDAR_ROUTE = '/eco_kalendar'
export const NOT_FOUND_ROUTE = '/404'
export const STATIC_ROUTE = '/strona'
export const CALENDAR_ROUTE = `${STATIC_ROUTE}/zielony-kalendarz-edukacyjny`