import { createTheme } from '@material-ui/core/styles';


const lessMobile = '@media (max-width: 450px)';
export const newTheme = () => {
    const theme = createTheme({
        typography: {
            fontFamily: 'sofia-pro, sans-serif',
            'button': {
                fontSize: 21,
                wordBreak: 'break-word',
                [lessMobile]: {
                    fontSize: 18,
                },
            },
            'body1': {
                fontSize: 21,
                wordBreak: 'break-word',
                marginBottom: 5,
                [lessMobile]: {
                    fontSize: 18,
                },
            },
            'body2': {
                fontWeight: 'bold',
                fontSize: 28,
                wordBreak: 'break-word',
                [lessMobile]: {
                    fontSize: 21,
                },
            },
            'h6': {},
            'h5': {
                fontSize: 28,
                wordBreak: 'break-word',
                [lessMobile]: {
                    fontSize: 21,
                },
            },
            'h4': {
                fontSize: 32,
                fontWeight: 'bold',
                wordBreak: 'break-word',
                [lessMobile]: {
                    fontSize: 28,
                },
            },
            'h3': {
                fontSize: 30,
                fontWeight: 'bold',
                marginBottom: 10,
                wordBreak: 'break-word',
                [lessMobile]: {
                    fontSize: 28,
                },
            },
            'h2': {
                fontSize: 54,
                marginBottom: 40,
                fontWeight: 'bold',
                wordBreak: 'break-word',
                [lessMobile]: {
                    fontSize: 36,
                },
            },
            'h1': {},
        },
    })
    return theme.typography;
}

const TextTheme = newTheme();
export default TextTheme