import React, { useEffect } from 'react'
import { Box, makeStyles, } from '@material-ui/core';
import MainContainerComponent from '../../components/MainContainerComponent';
import MainHeroImageComponent from '../../components/MainHeroImageComponent';
import EcoZonesSectionComponent from './components/EcoZonesSectionComponent';
// import SectionComponent from '../../components/SectionComponent';
// import GreenCityBackgroundSvg from '../../svgs/GreenCityBackgroundSvg';
import GreenCitySecondBackgroundSvg from '../../svgs/GreenCitySecondBackgroundSvg';
// import ImagesSliderComponent from '../../components/ImagesSliderComponent';
// import AlwaysEcoSectionComponent from './components/AlwaysEcoSectionComponent';
import { useSelector } from 'react-redux';
import { FETCH_MAIN_PAGE_REDUCER, FETCH_CACHE_MAIN_PAGE_REDUCER } from '../../reducers/MainPageReducer';
import CardsSectionComponent from '../../components/CardsSectionComponent';
import { CURRENT_VERSION, INDEX_DB_MAIN_MODEL_NAME } from '../../constants';
import loadable from '@loadable/component'
import AirQualityComponent from '../../components/AirQualityComponent';
import WeatherComponent from '../../components/WeatherComponent';
import DataTitleComponent from '../../components/DataTitleComponent';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ConferenceCardSectionComponent from '../../components/ConferenceCardSectionComponent';
import SboCardSectionComponent from '../../components/SboCardSectionComponent';
const AlwaysEcoSectionComponent = loadable(() => import('./components/AlwaysEcoSectionComponent'))
const ImagesSliderComponent = loadable(() => import('../../components/ImagesSliderComponent'))


const useStyles = makeStyles((theme) => ({
    ecoZonesSection: {
        background: theme.palette.background.grayGradient,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
    },
    boxSections: {
        position: 'relative',
    },
    secondBackground: {
        position: 'absolute',
        bottom: 0,
        zIndex: 0,
        right: 0,
        width: '100vw'
    },
    background: {
        position: 'absolute',
        bottom: -50,
        zIndex: 0,
        right: '-10vw',
        width: '80vw'
    },
    dataBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    }
}))


const MainPage = (props) => {
    const classes = useStyles();
    const { data, loading } = useSelector((state) => state.MainPageReducer);
    const {t} = useTranslation();

    useEffect(() => {
        const currentVersion = localStorage.getItem(CURRENT_VERSION);
        if (data?.currentVersion && currentVersion !== data?.currentVersion && caches) {
            caches.keys().then(function (names) {
                for (let name of names)
                    caches.delete(name);
            });
            localStorage.setItem(CURRENT_VERSION, data?.currentVersion)
        }

    }, [data])

    return (
        <MainContainerComponent
            disableSpace
            title={t('main_page.title')}
            fetchFun={FETCH_MAIN_PAGE_REDUCER}
            fetchCacheFun={FETCH_CACHE_MAIN_PAGE_REDUCER}
            indexDbModelName={INDEX_DB_MAIN_MODEL_NAME}
        >
            <MainHeroImageComponent
                loading={loading}
                info={data?.news}
            />
            <EcoZonesSectionComponent
                cards={data?.pages}
                loading={loading}
                showTitle
                showBackgroundIcons
            >
                <Box className={classes.dataBox}>

                    <DataTitleComponent
                        loading={loading}
                        title={t('date.title')}
                        name={moment().format('DD-MM-YYYY')}
                        hiddenSrc
                    />
                    <WeatherComponent
                        loading={loading}
                        data={data?.weather}
                    />
                    <AirQualityComponent
                        loading={loading}
                        data={data?.air_quality}
                    />
                </Box>
            </EcoZonesSectionComponent>
            <Box className={classes.boxSections}>
                <ConferenceCardSectionComponent />
                <SboCardSectionComponent />
                <CardsSectionComponent
                    loading={loading}
                    fullWidth
                    sections={data?.sectionsCards}
                />
                {/* <GreenCityBackgroundSvg
                    className={classes.background}
                /> */}
                <GreenCitySecondBackgroundSvg
                    className={classes.secondBackground}
                />
            </Box>
            <ImagesSliderComponent
                images={data?.images}
                loading={loading}
            />
            {data?.events?.length
                ? <AlwaysEcoSectionComponent
                    loading={loading}
                    events={data?.events}
                />
                : null
            }

        </MainContainerComponent>
    )
}

export default React.memo(MainPage);