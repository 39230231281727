export const MENU_API = '/menu'
export const MAIN_API = '/page/main'
export const WATER_API = '/page/woda'
export const NOISE_API = '/page/halas'
export const EDUCATION_API = '/page/edukacja'
export const ECO_PROMOTION_API = '/page/eko-akcje'
export const INSTITUTIONS_API = '/page/instytucje'
export const WASTES_API = '/page/odpady'
export const AIR_API = '/page/powietrze'
export const CONTACT_API = '/contact'
export const LAND_API = '/page/ziemia'
export const STRATEGY_API = '/page/strategia'
export const GREEN_API = '/page/zielen'
export const ANIMALS_API = '/page/zwierzeta'
export const ECO_CALENDAR_API = '/page/eco_calendar'
export const STATIC_API = '/page'
