import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';
import HearingLossSvg from '../svgs/HearingLossSvg';
import DisabledPersonSvg from '../svgs/DisabledPersonSvg';
import { ACCESS_BIGGEST_TEXT, ACCESS_BIG_TEXT, ACCESS_CONTARST, ACCESS_TEXT, BIP_LOSS, DISABLED_LOSS, HEARING_LOSS } from '../constants';
import BipSvg from '../svgs/BipSvg';
import EyeSvg from '../svgs/EyeSvg';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_MAIN_REDUCER } from '../reducers/MainReducer';

const useStyles = makeStyles((theme) => ({
	toolbarBox: {
		background: theme.palette.header.darkest,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		position: 'relative',
		zIndex: 10,
	},
	bodyBox: {
		...theme.custom.header,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		color: theme.palette.text.white,
		'& > *': {
			cursor: 'pointer',
			marginLeft: theme.spacing(2),
		}
	},
	bipIcon: {
		height: 30,
	},
	eyeIcon: {
		height: 17,
		'& path, circle': {
			stroke: theme.palette.text.white,
		},
	},
	icon: {
		// width: 16,
		height: 20,
		'& path, circle': {
			stroke: theme.palette.text.white,
		},
	}
}))

const HeaderToolbarComponent = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { data } = useSelector(state => state.MainReducer);
	const handleToggleAccess = useCallback((id, value) => () => {
	
		let newValue = '';
		if (value) {
			newValue = data[id] === value ? '' : value
		} else {
			const active = data[id]
			newValue = active ? '' : true
		}
		localStorage.setItem(id, newValue)
		dispatch(UPDATE_MAIN_REDUCER({ id, newValue }))
	}, [dispatch, data])
	return (
		<>
		<Box className={classes.toolbarBox}>
			<Box className={classes.bodyBox}>
				<Typography onClick={handleToggleAccess(ACCESS_TEXT, '')}>A</Typography>
				<Typography onClick={handleToggleAccess(ACCESS_TEXT, ACCESS_BIG_TEXT)}>A +</Typography>
				<Typography onClick={handleToggleAccess(ACCESS_TEXT, ACCESS_BIGGEST_TEXT)}>A ++</Typography>
				<LinkComponent to={BIP_LOSS} external_link>
					<BipSvg className={classes.bipIcon} />
				</LinkComponent>
				<EyeSvg className={classes.eyeIcon} onClick={handleToggleAccess(ACCESS_CONTARST)} />
				<LinkComponent to={DISABLED_LOSS} external_link>
					<DisabledPersonSvg className={classes.icon} />
				</LinkComponent>
				<LinkComponent to={HEARING_LOSS} external_link>
					<HearingLossSvg className={classes.icon} />
				</LinkComponent>
			</Box>
		</Box>
		</>
	)
}


HeaderToolbarComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(HeaderToolbarComponent);