import React from 'react'
import PropTypes from 'prop-types';
import LinkComponent from './LinkComponent';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const LogoLinkComponent = ({ onClick = () => { }, src, title, Logo, to, external_link, className = '' }) => {

	return (
		<LinkComponent
			to={to}
			external_link={external_link}

		>
			{Logo
				? <Logo
					className={className}
					onClick={onClick}
				/>
				: <LazyLoadImage
					className={className}
					onClick={onClick}
					src={src}
					alt={title}
				/>
			}

		</LinkComponent>
	)
}


LogoLinkComponent.propTypes = {
	to: PropTypes.string,
	external_link: PropTypes.bool,
}
export default React.memo(LogoLinkComponent);