import * as React from "react"

function ArrowSvg(props) {
	return (
		<svg
			viewBox="0 0 139 144"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit={2}
			{...props}
		>
			<path
				d="M133.64 140.643a5.079 5.079 0 00.83-5.503L73.727 3.337A5.07 5.07 0 0070.438.563a5.085 5.085 0 00-5.914 2.769L3.634 135.066c-.121.262-.22.533-.295.813-.721 2.685.897 5.488 3.58 6.209.96.257 1.975.228 2.916-.086l59.224-19.704 59.203 19.766c1.913.64 4.034.08 5.382-1.422l-.003.001zM17.75 128.687L69.117 17.555l51.244 111.187-46.221-15.437.027-47.023a5.08 5.08 0 00-3.755-4.916c-2.685-.721-5.487.895-6.208 3.58a5.103 5.103 0 00-.174 1.293l-.01.033-.028 47.023-46.242 15.392z"
				fill="#fff"
				fillRule="nonzero"
			/>
		</svg>
	)
}

export default ArrowSvg
