import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LINE_CSS } from '../constants/csses';

const useStyles = (bottomTitle) => makeStyles((theme) => ({
	titleBox: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: bottomTitle ? theme.spacing(2) : theme.spacing(5),
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			marginBottom: bottomTitle ? theme.spacing(2) : theme.spacing(3),
		},
	},

	subtitle: {
		marginBottom: bottomTitle ? theme.spacing(5) : theme.spacing(1),
		textTransform: 'uppercase',
	},
	title: {
		marginBottom: 0,
	},
	icon: {
		maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
		height: 50,
		objectFit: 'contain',
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
			marginRight: 0
		}
	},
	line: {
		...LINE_CSS(theme)
	}
}))

const TitleComponent = ({ line, bottomTitle, className = '', startIcon, variant = 'h2', variantSubtitle = 'h3', title, subtitle }) => {
	const classes = useStyles(bottomTitle)();

	return (
		<Box className={classNames([classes.box, className])}>
			{!bottomTitle && subtitle
				? <Typography
					component={variantSubtitle}
					variant='body1'
					color='primary'
					className={classes.subtitle}
				>
					{subtitle}
				</Typography>
				: line
					? <hr className={classes.line} />
					: null
			}
			{title || startIcon ? <Box className={classes.titleBox}>
				{startIcon
					? <LazyLoadImage
						effect='blur'
						src={startIcon}
						title={title}
						className={classes.icon}
					/>
					: null
				}
				<Typography variant='h2' component={variant} className={classes.title}>{title}</Typography>
			</Box> : null
			}
			{bottomTitle && subtitle
				? <Typography
					component={variantSubtitle}
					variant='body1'
					color='primary'
					className={classes.subtitle}
				>
					{subtitle}
				</Typography>
				: null
			}

		</Box>
	)
}


TitleComponent.propTypes = {
	variant: PropTypes.string,
	subtitle: PropTypes.string,
	title: PropTypes.string,
	bottomTitle: PropTypes.bool,
	line: PropTypes.bool,
}
export default React.memo(TitleComponent);