import React from 'react'
import { IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	iconBtn: {
		marginTop: theme.spacing(1),
		background: '#FFFFFF17'
	},
	newsIcon: {
		fontSize: theme.typography.body2.fontSize,
		color: theme.palette.text.white,
	}
}))

const ArrowBtnComponent = ({className = '',  onClick = () => {}}) => {
 const classes = useStyles();

	return (
		<IconButton className={classNames([classes.iconBtn, className])} onClick={onClick}>
			<ArrowForwardIosIcon className={classes.newsIcon} />
		</IconButton>
	)
}


ArrowBtnComponent.propTypes = {
	onClick: PropTypes.func,
}
export default React.memo(ArrowBtnComponent);