import * as React from "react"

function LogoSvg(props) {
	return (
		<svg
			viewBox="0 0 297 27"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit={2}
			{...props}
		>
			<path
				d="M0 15.767h15.927v3.376H0v-3.376zm.718-7.332h15.926v3.376H.718V8.435zm4-5.921h3.018l-2.809 23.33H1.849l2.869-23.33zm6.779 0h3.015l-2.809 23.33H8.625l2.872-23.33z"
				fill="#161615"
				fillRule="nonzero"
			/>
			<path
				d="M21.556 24.26l11.916-13.459H22.883v-2.4h14.858v1.578L25.826 23.401h12.238v2.44H21.556V24.26zM51.846 10.801h-8.075v-2.4h11.053v17.443h-2.979l.001-15.043zm-1.184-8.363l-.001-.069A2.38 2.38 0 0153.03 0a2.38 2.38 0 012.369 2.369 2.38 2.38 0 01-2.369 2.369h-.001a2.254 2.254 0 01-2.368-2.24l.001-.06zM64.514 17.083a8.08 8.08 0 01-.007-.348c0-4.811 3.959-8.77 8.77-8.77l.137.001c5.024 0 7.968 3.266 7.968 8.183a12.261 12.261 0 01-.144 1.795H66.487v-2.3H79.48l-.754.826c0-4.128-2.047-6.173-5.24-6.173-3.231 0-6.03 2.441-6.03 6.784 0 4.522 2.943 6.819 6.82 6.819a9.495 9.495 0 005.31-1.651l1.078 1.939a11.957 11.957 0 01-6.749 2.082c-5.239.002-9.401-3.374-9.401-9.187zM92.435 19.99V2.69h-6.172V.29h9.152v19.92c0 2.513 1.363 3.588 3.446 3.588a7.87 7.87 0 003.265-.789l.755 2.225a10.612 10.612 0 01-4.666 1.043c-3.733-.005-5.78-2.16-5.78-6.287zM107.293 17.153c0-5.849 4.02-9.187 8.615-9.187 4.595 0 8.613 3.339 8.613 9.187 0 5.779-4.02 9.119-8.613 9.119s-8.615-3.336-8.615-9.119zm14.177 0c0-4.02-2.225-6.71-5.562-6.71s-5.565 2.691-5.565 6.71c0 3.984 2.226 6.64 5.565 6.64 3.339 0 5.562-2.657 5.562-6.64zM130.011 8.397h2.44l.251 2.943h.144c1.832-1.9 3.841-3.374 6.568-3.374 4.129 0 6.031 2.441 6.031 7.035v10.835h-2.98v-10.44c0-3.3-1.149-4.846-3.984-4.846-2.081 0-3.481 1.041-5.491 3.122v12.164h-2.978l-.001-17.439zM150.646 17.083a8.033 8.033 0 01-.007-.347c0-4.812 3.96-8.771 8.771-8.771l.136.001c5.024 0 7.967 3.266 7.967 8.183a11.991 11.991 0 01-.144 1.795H152.62v-2.3h12.992l-.752.826c0-4.128-2.047-6.173-5.242-6.173-3.23 0-6.029 2.441-6.029 6.784 0 4.522 2.944 6.819 6.819 6.819a9.505 9.505 0 005.313-1.651l1.077 1.939a11.96 11.96 0 01-6.747 2.082c-5.242.002-9.405-3.374-9.405-9.187z"
				fill="#4eb265"
				fillRule="nonzero"
			/>
			<path
				d="M171.821 8.397h2.37l.251 2.3h.071c.9-1.615 1.974-2.728 3.983-2.728 1.724 0 2.8 1.005 3.3 3.015 1.005-1.867 2.082-3.015 4.092-3.015 2.44 0 3.876 1.9 3.876 5.383v12.484h-2.943V13.565c0-2.01-.574-3.051-1.866-3.051-1.221 0-1.867.9-2.8 2.692v12.63h-2.728V13.565c0-2.01-.5-3.051-1.793-3.051-1.221 0-2.011.9-2.872 2.692v12.63h-2.944l.003-17.439zM202.579 10.801h-8.075v-2.4h11.053v17.443h-2.979l.001-15.043zm-1.184-8.363l-.001-.069A2.38 2.38 0 01203.763 0a2.38 2.38 0 012.369 2.369 2.38 2.38 0 01-2.369 2.369h-.001a2.254 2.254 0 01-2.368-2.24l.001-.06zM215.677 21.318c0-3.913 3.661-5.814 12.67-6.532-.107-2.369-1.256-4.379-4.629-4.379a12.155 12.155 0 00-6.1 2.081l-1.185-2.011a15.295 15.295 0 017.789-2.511c4.774 0 7.108 2.835 7.108 7.178v10.7h-2.477l-.251-2.37h-.108a11.727 11.727 0 01-6.856 2.8c-3.27-.002-5.961-1.838-5.961-4.956zm12.67-.036V16.76c-7.465.574-9.726 2.045-9.726 4.343 0 1.974 1.795 2.763 3.8 2.763 1.979 0 3.916-.93 5.926-2.583v-.001zM236.887 23.543l1.365-1.974a12.45 12.45 0 007.573 2.37c2.943 0 4.379-1.221 4.379-2.693 0-1.328-.682-2.261-5.418-3.23-4.559-.932-6.821-2.476-6.821-5.1 0-2.8 2.477-4.953 7.25-4.953 2.51.052 4.953.825 7.036 2.226l-1.436 1.9a10.306 10.306 0 00-5.851-1.832c-2.943 0-4.02 1.185-4.02 2.513 0 1.508 1.508 2.154 5.1 2.907 5.779 1.256 7.179 2.692 7.179 5.385 0 2.835-2.763 5.2-7.753 5.2a15.34 15.34 0 01-8.583-2.719zM263.266 19.058v-8.257h-4.958V8.576l5.061-.178.395-5.6h2.477v5.6h8.542v2.4h-8.542v8.291c0 3.124 1.04 4.739 4.414 4.739a11.447 11.447 0 004.163-.79l.647 2.189a15.223 15.223 0 01-5.455 1.043c-5.093.002-6.744-2.906-6.744-7.212zM279.562 17.153c0-5.849 4.02-9.187 8.613-9.187s8.615 3.339 8.615 9.187c0 5.779-4.02 9.119-8.615 9.119-4.595 0-8.613-3.336-8.613-9.119zm14.178 0c0-4.02-2.226-6.71-5.565-6.71-3.339 0-5.562 2.691-5.562 6.71 0 3.984 2.225 6.64 5.562 6.64s5.562-2.657 5.562-6.64h.003z"
				fill="#0da6ee"
				fillRule="nonzero"
			/>
		</svg>
	)
}

export default LogoSvg
