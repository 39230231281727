import { useTranslation } from "react-i18next"
import { CONFERENCE_ROUTE } from "../constants/routes";
import { Container, makeStyles } from "@material-ui/core";

import image from '../imgs/conference/card.jpeg';
import CardComponent from "./CardComponent";
import TextSectionComponent from "./TextSectionComponent";

const useStyles = makeStyles((theme) => ({
  collapsible: {
    '& .MuiTypography-body1': {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 8
    },
    '& h3': {
      fontFamily: 'sofia-pro, sans-serif',
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '1.167'
    }
  },
}))

const ConferenceCardSectionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container className={classes.collapsible}>
      <TextSectionComponent
        fullWidth
        title={t('conference_page.section_title')}
      />
      <CardComponent
        rowReverse
        to={CONFERENCE_ROUTE}
        src={image}
        btnTitle={t('btns.read_more')}
        title={t('conference_page.card_title')}
        description={t('conference_page.description')}
    />
    </Container>
  )
}

export default ConferenceCardSectionComponent;