import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { INDEX_DB_VERSION, INDEX_DB_NAME, INDEX_DB_MAIN_MODEL_NAME } from '../constants';
import { MAIN_API } from '../constants/api';
import { openDB } from '../functions/indexDB';
import { GET } from "../functions/superagentSending";

export const FETCH_MAIN_PAGE_REDUCER = createAsyncThunk(
	'MainPageReducer/fetchMainPage',
	async () => {
		const response = await GET(MAIN_API);
		if (response?.body?.data) {
			const indexesDB = [
				{
					name: 'news',
					unique: false,
				},
				{
					name: 'events',
					unique: false,
				},
				{
					name: 'images',
					unique: false,
				},
				{
					name: 'sectionsCards',
					unique: false,
				},
				{
					name: 'squareCards',
					unique: false,
				},
				{
					name: 'slug',
					unique: false,
				},
			]
			openDB(INDEX_DB_NAME, INDEX_DB_VERSION, INDEX_DB_MAIN_MODEL_NAME, 'slug', indexesDB, [{
				...response.body.data,
				slug: 'main',
			}])
		}
		return response.body.data
	}
)


export const MainPageReducer = createSlice({
	name: 'MainPageReducer',
	initialState: {
		data: {},
		loading: false,
		error: {},
	},
	reducers: {
		FETCH_CACHE_MAIN_PAGE_REDUCER: (state, { payload }) => {
			state.data = payload
		}
	},
	extraReducers: {
		[FETCH_MAIN_PAGE_REDUCER.pending]: (state) => {
			state.loading = true;
		},
		[FETCH_MAIN_PAGE_REDUCER.fulfilled]: (state, action) => {
			state.error = {};
			state.data = 
			{
				...action.payload,
				// events: [
				// 	{
				// 		name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
				// 		src: 'https://www.polska.travel/images/pl-PL/glowne-miasta/szczecin/szczecin_tarasy_widokowe_1170.jpg',
				// 		slug: '/sfsfs',
				// 		date: 1628059131924,
				// 	},
				// 	{
				// 		name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
				// 		src: 'https://www.polska.travel/images/pl-PL/glowne-miasta/szczecin/szczecin_tarasy_widokowe_1170.jpg',
				// 		slug: '/sfsfs',
				// 		date: 1628059131924,
				// 	},
				// 	{
				// 		name: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed',
				// 		src: 'https://www.polska.travel/images/pl-PL/glowne-miasta/szczecin/szczecin_tarasy_widokowe_1170.jpg',
				// 		slug: '/sfsfs',
				// 		date: 1628059131924,
				// 	},
				// ],
			}
			state.loading = false;
		},
		[FETCH_MAIN_PAGE_REDUCER.rejected]: (state, action) => {
			state.data = {};
			state.loading = false;
			state.error = action;
		}
	}
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, FETCH_CACHE_MAIN_PAGE_REDUCER } = MainPageReducer.actions

export default MainPageReducer.reducer