import React, { useEffect } from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core';
import MainContainerComponent from '../../components/MainContainerComponent';
import NotFoundSvg from '../../svgs/NotFoundSvg';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../components/form/ButtonComponent';
import LinkComponent from '../../components/LinkComponent';
import { MAIN_ROUTE } from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { CLEAR_STATE } from '../../reducers/MainReducer';

const useStyles = makeStyles((theme) => ({
	icon: {
		maxWidth: '95%',
		margin: 'auto',

	},
	title: {
		marginTop: theme.spacing(4),
		textAlign: 'center',
	},
	cont: {
		marginTop: theme.spacing(10),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	}
}))

const NotFoundPage = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(CLEAR_STATE());
	}, [dispatch])
	return (
		<MainContainerComponent
			// disableSpace
			title='404'
			hiddenFetch
		>
			<Container className={classes.cont}>
				<NotFoundSvg
					className={classes.icon}
				/>
				<Typography variant='h2' className={classes.title}>{t('not_found_page.title')}</Typography>
				<LinkComponent to={MAIN_ROUTE} title={t('btns.back_main')}>
					<ButtonComponent
						back
						title={t('btns.back_main')}
					/>
				</LinkComponent>
			</Container>
		</MainContainerComponent>
	)
}

export default React.memo(NotFoundPage);