// import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
import MainReducer from './MainReducer'
import MainPageReducer from './MainPageReducer'
import EducationPageReducer from './EducationPageReducer'
import InstitutionsPageReducer from './InstitutionsPageReducer'
import WastesPageReducer from './WastesPageReducer'
import EcoPromotionPageReducer from './EcoPromotionPageReducer'
import AirPageReducer from './AirPageReducer'
import LandPageReducer from './LandPageReducer'
import StrategyPageReducer from './StrategyPageReducer'
import WaterPageReducer from './WaterPageReducer'
import NoisePageReducer from './NoisePageReducer'
import EcoCalendarPageReducer from './EcoCalendarPageReducer'
import AnimalsPageReducer from './AnimalsPageReducer'
import StaticPageReducer from './StaticPageReducer'
import GreenPageReducer from './GreenPageReducer'
import { configureStore, combineReducers } from '@reduxjs/toolkit'


const appReducer = combineReducers({
    MainReducer,
    NoisePageReducer,
    WaterPageReducer,
    MainPageReducer,
    EducationPageReducer,
    InstitutionsPageReducer,
    WastesPageReducer,
    EcoPromotionPageReducer,
    AirPageReducer,
    LandPageReducer,
    StrategyPageReducer,
    GreenPageReducer,
    AnimalsPageReducer,
    EcoCalendarPageReducer,
    StaticPageReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'MainReducer/CLEAR_STATE') {
        state = undefined
    }

    return appReducer(state, action)
}
export default configureStore({
    reducer: rootReducer,
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

})