import * as React from "react"

function EcoZoneLineTopBackgroundSvg(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={2468.346}
			height={1159.02}
			viewBox="0 0 2468.346 1159.02"
			{...props}
		>
			<g
				data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 859"
				fill="none"
				stroke="#fff"
				strokeWidth={2}
			>
				<path
					data-name="\u041A\u043E\u043D\u0442\u0443\u0440 508"
					d="M894.915 1044.362c24.834-9.013 123.643-54.254 0-298.695-72.7-143.731-11.292-209.659 51.828-239.671 43.925-20.885 86.176-36.771 153-56.078 123.3-32.69 566.75-114.793 688.253 7.5 509.5 503.547-434.912 700.627-434.912 700.627"
				/>
				<g
					data-name="\u0421\u0433\u0440\u0443\u043F\u043F\u0438\u0440\u043E\u0432\u0430\u0442\u044C 857"
					strokeMiterlimit={10}
				>
					<path
						data-name="\u041A\u043E\u043D\u0442\u0443\u0440 509"
						d="M673.66 850.408c35.641-129.991-58.724-228.117-448.868-455.887C-63.175 226.403-8.603 122.65 39.732 79.836c17.151-15.191 51.427-29.739 94-34.188 70.952-7.415 210.406-7.667 449.613 43.721 438.389 94.177 990.73-247.946 1884.729 9.837"
					/>
					<path
						data-name="\u041A\u043E\u043D\u0442\u0443\u0440 510"
						d="M827.123 858.82c48.345-217.634-450.406-404.4-87.859-540.98s971.946-13.994 1117.041 50.463 381.979 282.1 611.766 286.936"
					/>
				</g>
			</g>
		</svg>
	)
}

export default EcoZoneLineTopBackgroundSvg
