import React from 'react'
import { Box, Button, makeStyles } from '@material-ui/core';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	iconButton: {
		padding: 5,

	},
	iconButtonLabel: {
		position: 'relative',
		height: '1.8rem',
		width: '2.6rem',
		
		padding: 2,
		'& *': {
			transition: theme.custom.transition
		},
		'& span': {
			height: '0.25rem',
			borderRadius: 6,
			width: '100%',
			position: 'absolute',
		}
	},
	disableIconButtonLabel: {
		'& span': {
			background: theme.palette.burger.disable,
			'&:nth-child(1)': {
				top: 0,
			},
			'&:nth-child(2)': {
				transform: 'translate(0, -50%)',
				top: '50%',
			},
			'&:nth-child(3)': {
				bottom: 0,
			},
		}
	},
	activeIconButtonLabel: {
		'& span': {
			background: theme.palette.burger.active,
			
			'&:nth-child(1)': {
				transform: 'rotate(45deg)',
				top: '50%',
			},
			'&:nth-child(2)': {
				transform: 'translate(0, -50%)',
				top: '50%',
				opacity: 0,
			},
			'&:nth-child(3)': {
				transform: 'rotate(-45deg)',
				top: '50%',
				bottom: 0,
			},
		}
	}
}))

const BurgerMenuComponent = ({ active, onClick = () => { }, classNameBox = '', }) => {
	const classes = useStyles();

	return (
		<Box className={classNames([classes.box, classNameBox])}>
			<Button
				// className={classes.iconButton}
				size='small'
				onClick={onClick}
				classes={{
					root: classes.iconButton,
					label: `${classes.iconButtonLabel} ${active
						? classes.activeIconButtonLabel
						: classes.disableIconButtonLabel}`,
				}}
			>
				<span></span>
				<span></span>
				<span></span>
				{/* <MenuIcon className={classes.icon} /> */}
			</Button>
		</Box>
	)
}


export default React.memo(BurgerMenuComponent);