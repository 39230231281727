import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import Root from './Root';
import mainTheme from './themes/MainTheme';
import textTheme from './themes/TextTheme';
import textBiggestTheme from './themes/TextBiggestTheme';
import contrastTheme from './themes/ContrastTheme';
import moment from 'moment';
import 'moment/locale/pl';
import { CookiesProvider } from 'react-cookie';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ACCESS_CONTARST, ACCESS_TEXT, ACCESS_BIGGEST_TEXT, ACCESS_BIG_TEXT } from './constants';
import LoaderComponent from './components/LoaderComponent';
import ServiceWorkerWrapperComponent from './components/ServiceWorkerWrapperComponent'

function App() {
  const mainData = useSelector((state) => state.MainReducer.data);
  useEffect(() => {
    // eslint-disable-next-line 
    moment.locale('pl');
  }, []);
  return (
    <CookiesProvider>
      <MuiThemeProvider
        theme={{
          ...mainTheme,
          typography: {
            ...mainTheme.typography,
            ...(mainData[ACCESS_TEXT] === ACCESS_BIG_TEXT ? textTheme : {}),
            ...(mainData[ACCESS_TEXT] === ACCESS_BIGGEST_TEXT ? textBiggestTheme : {}),
          },
          palette: {
            ...mainTheme.palette,
            ...(mainData[ACCESS_CONTARST] ? contrastTheme.palette : {}),
          },
          overrides: {
            ...mainTheme.overrides,
            ...(mainData[ACCESS_CONTARST] ? contrastTheme.overrides : {}),
          },
        }}
      >
        <CssBaseline />
        <Suspense fallback={<LoaderComponent />}>
            <Root />
        </Suspense>
      </MuiThemeProvider>
      <ServiceWorkerWrapperComponent />
    </CookiesProvider>
  );
}

export default App;
