import { createTheme } from '@material-ui/core/styles';


const textWhite = '#ffffff';
const defaultColor = '#000000';
const whiteColor = '#000000';
const primary = '#ffff00';
const transition = 'all 0.3s ease-in-out'
const mobileBoxShadow = '2px 6px 10px #00000010';
const mobile = '@media (max-width: 959px)';
const lessMobile = '@media (max-width: 450px)';

export const newTheme = () => {
    const theme = createTheme({
        palette: {
            airQuality: {
                '-1': primary,
                '0': primary,
                '1': primary,
                '2': primary,
                '3': primary,
                '4': primary,
                '5': primary,
            },
            header: {
                main: defaultColor,
                dark: defaultColor,
                darkest: defaultColor,
            },
            button: {
                gradient: defaultColor
            },
            text: {
                primary: primary,
                white: primary
            },
            primary: {
                main:  primary,
                dark: primary,
                darkest: primary,
            },
            secondary: {
                main: primary,
            },
            gray: {
                light: primary,
                main: defaultColor,
            },
            background: {
                default: defaultColor,
                gray: defaultColor,
                white: whiteColor,
                darkBlue: defaultColor,
                darknessBlue: defaultColor,
                primaryGradient: defaultColor,
                grayGradient: defaultColor,
            },
            burger: {
                disable: primary,
                active: primary,
            }
        },
        overrides: {
            MuiPaper: {
                elevation1: {
                    background: defaultColor,
                    borderRadius: 30,
                    border: `1px solid ${primary}`,
                    transition: transition,
                    overflow: 'hidden',
                    '&:hover': {
                        background: defaultColor,
                        '& img': {
                            // filter: 'brightness(0) invert(1)',
                        },
                        '& *': {
                            color: textWhite,
                        },
                        '& svg': {
                            '& rect': {
                                fill: textWhite,
                            },
                            '& path': {
                                fill: textWhite,
                            },
                        },

                    },
                },
                elevation2: {
                    background: defaultColor,
                    borderRadius: 20,
                    border: `1px solid ${primary}`,
                    boxShadow: '2px 6px 50px #00000010',
                    transition: transition,
                    padding: 60, 
                    '& img': {
                        // filter: 'brightness(0) invert(1)',
                    },
                    [mobile]: {
                        padding: 40,
                        boxShadow: mobileBoxShadow ,
                    },
                    [lessMobile]: {
                        padding: 20,
                    },
                },
                elevation3: {
                    transition: transition,
                    border: `1px solid ${primary}`,
                    background: defaultColor,
                    boxShadow: 'none',
                    padding: 0,
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                    '& img': {
                        // filter: 'brightness(0) invert(1)',
                    },
                    [mobile]: {
                        boxShadow: mobileBoxShadow,
                    },
                },
                elevation4: {
                    background: defaultColor,
                    borderRadius: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    boxShadow: 'none',
                    transition: transition,
                    border: `1px solid ${primary}`,
                    padding: 60,
                    [mobile]: {
                        padding: 40,
                    },
                    [lessMobile]: {
                        padding: 20,
                    },
                },
            }
        },
    })
    return {
        ...theme,
    }
}

const ContrastTheme = newTheme();
export default ContrastTheme