import React from 'react'
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
	grid: {
		padding: theme.spacing(1),
	},
	paper: {
		padding: 0,
		height: 400, 
		overflow: 'hidden',
	},
	skeleton: {
		width: '100%',
		height: '100%',
	}
}))

const LargeCardSkeletonComponent = (props) => {
	const classes = useStyles();

	return (
		<Grid md={6} xs={12} item className={classes.grid}>
			<Paper elevation={2} className={classes.paper}>
				<Skeleton
					variant='rect'
					className={classes.skeleton}
				/>
			</Paper>
		</Grid>
	)
}

export default React.memo(LargeCardSkeletonComponent);