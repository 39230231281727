import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import SkeletonComponent from './skeletons/SkeletonComponent';

const useStyles = makeStyles((theme) => ({
	textBox: {
		marginRight: theme.spacing(1),
		textAlign: 'end'
	},

	iconSkeleton: {
		width: 50,
		height: 50,
		borderRadius: '50%',
		overflow: 'hidden',
	},
	mainBox: {
		display: 'flex',
		marginLeft: theme.spacing(4),
		alignItems: 'center',
		justifyContent: 'flex-end',
		marginBottom: theme.spacing(2),
		textTransform: 'uppercase',
	},

	skeletonTitle: {
		height: theme.typography.body1.fontSize
	},
}))

const DataTitleComponent = ({ title, loading, name, children, className, hiddenSrc }) => {
	const classes = useStyles();

	return (
		<Box className={classes.mainBox}>
			<Box className={classes.textBox}>
				<Typography>{title}</Typography>
				{loading
					? <Skeleton
						className={classes.skeletonTitle}
					/>
					: <Typography
						className={className}
					>
						{name}
					</Typography>
				}

			</Box>
			{hiddenSrc
				? ''
				: <Box>
					{loading
						? <SkeletonComponent className={classes.iconSkeleton} />
						: <>{children}</>
					}

				</Box>
			}

		</Box>
	)
}


DataTitleComponent.propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool,
}
export default React.memo(DataTitleComponent);