import React from 'react'
import SquareContainerComponent from '../SquareContainerComponent';
import { Skeleton } from '@material-ui/lab';


const SquareCardSkeletonComponent = (props) => {

	return (
		<SquareContainerComponent>
			<Skeleton
				variant='rect'
				width='100%'
				height='100%'
			/>
		</SquareContainerComponent>
	)
}

export default React.memo(SquareCardSkeletonComponent);