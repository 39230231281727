import React, { useMemo } from 'react'
import { Grid, makeStyles, Paper } from '@material-ui/core';
import {  useLocation } from 'react-router';
import { classNames } from '../functions';

const useStyles = makeStyles((theme) => ({
	grid: {
		padding: theme.spacing(1),
		[theme.breakpoints.down('xs')]: {
			minWidth: '50%',
		},
	},
	paper: {

		position: 'relative',
		width: '100%',
		height: '100%',
		'&::after': {
			content: "''",
			display: 'block',
			paddingBottom: '100%',
		},
		'&:hover': {
			borderRadius: '50%',
		},
		'&.active': {
			borderRadius: '50%',
		},
	},
}))

const SquareContainerComponent = ({ onClick = () => {}, customActive, children, to}) => {
	const classes = useStyles();
	const {pathname} = useLocation();
	const active = useMemo(() => pathname === to, [to, pathname])


	return (
		<Grid onClick={onClick} item lg={2} md={3} sm={4} xs={6} className={classes.grid}>
			<Paper className={classNames([classes.paper, customActive || active ? 'active' : ''])} elevation={1}>
				{children}
			</Paper>
		</Grid >
	)
}

export default React.memo(SquareContainerComponent);