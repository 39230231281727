import { Button, withStyles } from '@material-ui/core';

const CustomBtnComponent = withStyles((theme) => ({
    root: {
        borderRadius: 30,
        borderWidth: 2,
        padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
}))(Button);


export default CustomBtnComponent;