import React from 'react'
import { Box, Container, makeStyles } from '@material-ui/core';
import CardSkeletonComponent from './skeletons/CardSkeletonComponent';
import loadable from '@loadable/component'
import TextSectionComponent from './TextSectionComponent';
const CardComponent = loadable(() => import('./CardComponent'))
const TitleComponent = loadable(() => import('./TitleComponent'))

const useStyles = makeStyles((theme) => ({
	box: {
		position: 'relative',
		zIndex: 1,
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(5),
			paddingBottom: theme.spacing(2),
		},
	},
}))

const CardsSectionComponent = ({ line, sections, hiddenTitle, loading, fullWidth, subtitle, lastClassName = '' }) => {
	const classes = useStyles();

	return (
		<>
			{loading
				? <Container>
					<Box className={classes.box}>
						{hiddenTitle ? null : <TitleComponent />}
						<Box className={classes.boxCont}>
							<CardSkeletonComponent />
							<CardSkeletonComponent />
							<CardSkeletonComponent />
							<CardSkeletonComponent />
							<CardSkeletonComponent />
						</Box>
					</Box>
				</Container>
				: sections?.length
					? sections.map(({ fill, list = [], title, titleIcon, description }, sectionKey) =>
						<Box key={sectionKey} className={sectionKey === (sections.length - 1) ? lastClassName : ''}>
							<Container  >
								<Box
									className={classes.box}

								>
									{hiddenTitle
										? null
										// : <TitleComponent
										// 	line={line}
										// 	startIcon={titleIcon}
										// 	title={title}
										// />
										: <TextSectionComponent
											line={line}
											fullWidth={fullWidth}
											startIcon={titleIcon}
											title={title}
											body={description}
										/>
									}

									<Box className={classes.boxCont}>
										{list?.length
											? list.map(({ src, title, description, btnTitle, to, icon, external_link }, keyCard) =>
												<CardComponent
													rowReverse={
														list?.length === 1
															? sectionKey % 2
															: keyCard % 2}
													key={keyCard}
													// borderCard
													external_link={external_link}
													to={to}
													primary={fill}
													iconSrc={icon}
													src={src}
													btnTitle={btnTitle}
													title={title}
													description={description}
												/>
											)
											: null
										}
									</Box>
								</Box>
							</Container>
						</Box>
					)
					: ''
			}

		</>
	)
}

export default React.memo(CardsSectionComponent);