import React from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import SquareCardSkeletonComponent from './skeletons/SquareCardSkeletonComponent';
import loadable from '@loadable/component'
const SquareCardComponent = loadable(() => import('./SquareCardComponent'))

const useStyles = makeStyles((theme) => ({
	grid: {
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'flex-start',
			flexWrap: 'nowrap',
			overflowX: 'scroll',
			paddingBottom: theme.spacing(2),

		},
	}
}))

const SquareCardsSectionComponent = ({ loading, cards = [] }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.grid} >
			
			{loading 
				? <>
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
					<SquareCardSkeletonComponent />
				</>
			:cards?.length
				? cards.map(({ title, src, to, external_link }, key) =>
					<SquareCardComponent
						key={key}
						title={title}
						icon={src}
						to={to}
						external_link={external_link}
					/>
				)
				: null
			}
		</Grid>
	)
}


SquareCardsSectionComponent.propTypes = {
	cards: PropTypes.array,
	loading: PropTypes.bool,
}
export default React.memo(SquareCardsSectionComponent);