import * as React from "react"

function HearingLossSvg(props) {
	return (
		<svg
			viewBox="0 0 36 43"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M4.825 15.124c0-6.984 5.746-12.73 12.73-12.73 6.985 0 12.73 5.746 12.73 12.73.07 3.28-1.198 6.452-3.512 8.779-1.755 1.755-3.073 4.17-3.073 9.657v.012c0 3.365-2.77 6.134-6.134 6.134h-.01c-3.347-.068-6.08-2.8-6.147-6.146"
				fill="none"
				stroke="#fff"
				strokeWidth={3.39206}
			/>
			<path
				d="M13.166 10.735a6.846 6.846 0 014.61-1.976c3.49.003 6.362 2.875 6.365 6.365a6.65 6.65 0 01-2.196 4.829"
				fill="none"
				stroke="#fff"
				strokeWidth={3.39206}
			/>
		</svg>
	)
}

export default HearingLossSvg
