import { API_URL } from '../constants';
import superagent from 'superagent';


const set = {
    'Access-Control-Allow-Origin': "*",
    "Access-Control-Allow-Methods": "GET,POST,OPTIONS,DELETE,PUT",
    "Accept": 'application/json',
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization, 'application/json'",
}

// const getLang = () => localStorage.getItem('lang') || PL_LNG;
export const POST = (url, data = {}) => {
    return superagent
        .post(`${API_URL}/api${url}`)
        .send(data)
        .set(set)
}

export const GET = (url, query = {}) => {
    return superagent
        .get(`${API_URL}/api${url}`)
        .query(query)
        .set(set)

}