import React from 'react'
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import LargeCardSkeletonComponent from './LargeCardSkeletonComponent';
import { mobileScrollCont } from '../../constants/csses';

const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: theme.spacing(5),
		...mobileScrollCont(theme),
	}
}))

const CardSkeletonComponent = ({ rowReverse}) => {
 const classes = useStyles();

	return (
		<Grid className={classes.container} container direction={rowReverse ? 'row-reverse' : 'row'}>
			<LargeCardSkeletonComponent />
			<LargeCardSkeletonComponent />
		</Grid>
	)
}


CardSkeletonComponent.propTypes = {
   name: PropTypes.string,
}
export default React.memo(CardSkeletonComponent);