import * as React from "react"

function EyeSvg(props) {
	return (
		<svg
			viewBox="0 0 47 30"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
			strokeMiterlimit={10}
			{...props}
		>
			<path
				d="M44.424 14.95S35.033 27.315 23.45 27.315 2.476 14.95 2.476 14.95 11.867 2.585 23.45 2.585 44.424 14.95 44.424 14.95z"
				fill="none"
				stroke="#fff"
				strokeWidth={3.13046}
			/>
			<circle
				cx={3.3}
				cy={3.3}
				r={3.3}
				fill="none"
				stroke="#fff"
				strokeWidth={2}
				transform="translate(18.285 10.098) scale(1.56523)"
			/>
		</svg>
	)
}

export default EyeSvg
