import React, { useCallback, useState } from 'react'
import { Box, ClickAwayListener, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { classNames } from '../functions';
import CityPagesSvg from '../svgs/CityPagesSvg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component'
const LinkComponent = loadable(() => import('./LinkComponent'))

const useStyles = makeStyles((theme) => ({
	showCityPages: {
		bottom: -280,
		boxShadow: '0 0px 25px 0 rgb(0 0 0 / 15%)',
	},
	pagesListBox: {
		position: 'absolute',
		height: 280,
		transition: theme.custom.transition,
		right: 0,
		overflowY: 'scroll',
		zIndex: 9,
		left: 0,
		background: theme.palette.background.default,
		'&::-webkit-scrollbar': {
			width: 6,
		},
		'&::-webkit-scrollbar-track': {
			background: theme.palette.gray.light,
		},
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.header.main,
		},
	},
	cityPagesMenuBox: {
		height: '100%',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		}
	},
	hiddenCityPages: {
		bottom: 0,
		boxShadow: 'none',
	},
	cityPagesBox: {
		cursor: 'pointer',
		height: '100%',
		display: 'flex',
		zIndex: 10,	
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(3),
		background: theme.palette.header.main,
		color: theme.palette.text.white,
		overflow: 'hidden',
		'& path': {
			stroke: theme.palette.text.white,
		},
		position: 'relative',
		'&:after': {
			transition: theme.custom.transition,
			background: theme.palette.header.dark,
			width: 0,
			height: 0,
			content: '" "',
			top: 0,
			left: 0,
			borderRadius: '0% 0% 100% 0%',
			zIndex: -1,
			position: 'absolute'
		},
		'&:hover': {
			'&:after': {
				width: '150%',
				height: '150%',
			}
		}
	},
	cityLogo: {
		width: 30,
		height: 30,
	},
	cityPagesTitle: {
		fontWeight: 'bold',
		padding: `0 ${theme.spacing(1)}px`,
		marginBottom: 0,
	},
	moreIcon: {
		transition: theme.custom.transition,
	},
	activeMoreIcon: {
		transform: 'rotate(180deg)',
	},

}))

const CityPagesComponent = (props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [showCityPages, setShowCityPage] = useState(false);
	const { data } = useSelector((state) => state.MainReducer);

	const handleToggleCityPages = useCallback(() => {
		setShowCityPage(!showCityPages)
	}, [showCityPages])

	const handleCloseCityPages = useCallback(() => {
		setShowCityPage(false)
	}, [])
	return (
		<ClickAwayListener onClickAway={handleCloseCityPages}>
			<Box className={classes.cityPagesMenuBox}>
				<Box className={classes.cityPagesBox} onClick={handleToggleCityPages}>
					<CityPagesSvg className={classes.cityLogo} />
					<Typography className={classes.cityPagesTitle}>{t('header.city_pages')}</Typography>
					<ExpandMoreIcon className={classNames([
						classes.moreIcon,
						showCityPages
							? classes.activeMoreIcon
							: ''
					])} />
				</Box>
				<List component="nav" aria-label="city menu" className={classNames([
					classes.pagesListBox,
					showCityPages
						? classes.showCityPages
						: classes.hiddenCityPages
				])
				}>

					{
						data?.cityPages?.length
							? data.cityPages.map((city, key) =>
								<ListItem button key={key}>
									<LinkComponent
										to={city.to}
										external_link={city.external_link}
									>
										<ListItemText primary={city.title} />
									</LinkComponent>
								</ListItem>
							)
							: null
					}

				</List>
			</Box>
		</ClickAwayListener>
	)
}


CityPagesComponent.propTypes = {
	name: PropTypes.string,
}
export default React.memo(CityPagesComponent);