import React, { useEffect } from 'react'
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import { classNames } from '../functions';
import { useState } from 'react';
import useInterval from '../hooks/useInterval';
import LinkComponent from './LinkComponent';
import { NEWS_ROUTE } from '../constants/routes';
import { useTranslation } from 'react-i18next';
import ArrowBtnComponent from './ArrowBtnComponent';
import ButtonComponent from './form/ButtonComponent';
import { DATE_FORMAT } from '../constants';
// import HeroLineSvg from '../svgs/HeroLineSvg';
import SkeletonComponent from './skeletons/SkeletonComponent';
import ImgWinterXl from '../imgs/heroImages/winterXl.jpg';
import ImgWinterLg from '../imgs/heroImages/winterLg.jpg';
import ImgWinterMd from '../imgs/heroImages/winterMd.jpg';
import ImgWinterSm from '../imgs/heroImages/winterSm.jpg';
import ImgWinterXs from '../imgs/heroImages/winterXs.jpg';
import ImgAutumnXl from '../imgs/heroImages/autumnXl.jpg';
import ImgAutumnLg from '../imgs/heroImages/autumnLg.jpg';
import ImgAutumnMd from '../imgs/heroImages/autumnMd.jpg';
import ImgAutumnSm from '../imgs/heroImages/autumnSm.jpg';
import ImgAutumnXs from '../imgs/heroImages/autumnXs.jpg';
import ImgSpringXl from '../imgs/heroImages/springXl.jpg';
import ImgSpringLg from '../imgs/heroImages/springLg.jpg';
import ImgSpringMd from '../imgs/heroImages/springMd.jpg';
import ImgSpringSm from '../imgs/heroImages/springSm.jpg';
import ImgSpringXs from '../imgs/heroImages/springXs.jpg';
import ImgSummerXl from '../imgs/heroImages/summerXl.jpg';
import ImgSummerLg from '../imgs/heroImages/summerLg.jpg';
import ImgSummerMd from '../imgs/heroImages/summerMd.jpg';
import ImgSummerSm from '../imgs/heroImages/summerSm.jpg';
import ImgSummerXs from '../imgs/heroImages/summerXs.jpg';
import PictureComponent from './PictureComponent';

const useStyles = makeStyles((theme) => ({
	heroImageBox: {
		position: 'relative',
		height: '80vh',
		width: '100%',
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	imgBox: {
		position: 'relative',
		width: '100%',
		height: '100%',
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
		position: 'absolute',
		bottom: '20vh',
		top: 0,
		zIndex: 2,
		left: 0,
		right: 0,
	},
	title: {
		color: theme.palette.text.white,
		fontSize: 130,
		fontWeight: 'bold',
		'& span': {
			color: theme.palette.primary.main
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: 70,
		},
	},
	sliderContainer: {
		position: 'absolute',
		bottom: 0,
		zIndex: 2,
		left: 0,
		display: 'flex',
		justifyContent: 'center',
		right: 0,
		[theme.breakpoints.down('sm')]: {
			bottom: theme.spacing(5),
		},
	},
	link: {
		flexBasis: '25%',
		width: '25%',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	linkSkeleton: {
		flexBasis: '25%',
		width: '25%',
		height: 150,
		borderRight: `1px solid ${theme.palette.gray.light}`,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		background: theme.palette.background.white,
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	buttonLink: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
		},
	},
	card: {
		padding: theme.spacing(3),
		background: theme.palette.background.white,
		width: '100%',
		height: '100%',
		transition: theme.custom.transition,
		minHeight: 120,
	},
	notLastCard: {
		borderRight: `1px solid ${theme.palette.gray.light}`
	},
	lastCard: {
		borderTopRightRadius: theme.custom.radius,
		background: theme.palette.background.darkBlue,
		color: theme.palette.text.white,
		// padding: theme.spacing(5),
		textAlign: 'center',
		position: 'relative',
		overflow: 'hidden',
		'&:after': {
			transition: theme.custom.transition,
			background: theme.palette.background.darknessBlue,
			width: 0,
			height: 0,
			content: '" "',
			top: 0,
			left: 0,
			borderRadius: '0% 0% 100% 0%',
			// zIndex: ,
			position: 'absolute'
		},
		'&:hover': {
			'&:after': {
				width: '150%',
				height: '150%',
			}
		}
	},
	firstCard: {
		borderTopLeftRadius: theme.custom.radius,
	},
	activeCard: {
		background: theme.palette.gray.main,
		borderColor: theme.palette.gray.main,
		boxShadow: theme.custom.boxShadow
	},
	infoCard: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	line: {
		position: 'absolute',
		zIndex: 1,
		bottom: '-5vw',
		right: '-15vw',
		width: '40vw',
		[theme.breakpoints.down('sm')]: {
			width: '100vw',
		},
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	linkTitle: {
		position: 'relative',
		zIndex: 1,
	},
	linkArrowIcon: {
		position: 'relative',
		zIndex: 1,
	},


}))

const MainHeroImageComponent = ({ info = [], loading }) => {
	const classes = useStyles();
	const [activeKey, setActiveKey] = useState(0);
	const { t } = useTranslation();
	const [alt, setAlt] = useState('');
	const [src, setSrc] = useState({});
	useInterval(() => {
		const length = info?.length - 1;
		setActiveKey(length <= activeKey ? 0 : (activeKey + 1))
	}, 2000)

	useEffect(() => {
		const monthNumber = parseInt(moment().format('M'));
		switch (true) {
			case monthNumber === 1 || monthNumber === 2 || monthNumber === 3:
				setSrc({
					xl: ImgWinterXl,
					lg: ImgWinterLg,
					md: ImgWinterMd,
					sm: ImgWinterSm,
					xs: ImgWinterXs,
				})
				setAlt(t('main_page.winter'))
				break;
			case monthNumber === 4 || monthNumber === 5 || monthNumber === 6:
				setSrc({
					xl: ImgSpringXl,
					lg: ImgSpringLg,
					md: ImgSpringMd,
					sm: ImgSpringSm,
					xs: ImgSpringXs,
				})
				setAlt(t('main_page.spring'))
				break;
			case monthNumber === 7 || monthNumber === 8 || monthNumber === 9:
				setSrc({
					xl: ImgSummerXl,
					lg: ImgSummerLg,
					md: ImgSummerMd,
					sm: ImgSummerSm,
					xs: ImgSummerXs,
				})
				setAlt(t('main_page.summer'))
				break;
			case monthNumber === 10 || monthNumber === 11 || monthNumber === 12:
				setSrc({
					xl: ImgAutumnXl,
					lg: ImgAutumnLg,
					md: ImgAutumnMd,
					sm: ImgAutumnSm,
					xs: ImgAutumnXs,
				})
				setAlt(t('main_page.autumn'))
				break;
			default:
				setSrc({
					xl: ImgWinterXl,
					lg: ImgWinterLg,
					md: ImgWinterMd,
					sm: ImgWinterSm,
					xs: ImgWinterXs,
				})
				setAlt(t('main_page.winter'))
				break;
		}
	}, [t])

	return (
		<Box className={classes.heroImageBox}>

			<Box className={classes.imgBox}>
				<PictureComponent
					className={classes.img}
					src={src}
					alt={alt}
				/>
				<Container className={classes.titleContainer}>
					<Typography
						variant='h1'
						className={classes.title}
						dangerouslySetInnerHTML={{ __html: t('main_page.title') }}
					/>
				</Container>
			</Box>
			<Container className={classes.sliderContainer}>

				{loading
					? <>
						<SkeletonComponent className={classes.linkSkeleton} />
						<SkeletonComponent className={classes.linkSkeleton} />
						<SkeletonComponent className={classes.linkSkeleton} />
						<SkeletonComponent className={classes.linkSkeleton} />
					</>
					: info?.length
						// : false
						? <>
							{info.map(({ to, date, title }, key) =>
								<LinkComponent to={to} key={key} external_link className={classes.link}>
									<Box className={classNames([
										classes.card,
										key !== 0
											? classes.notFirstCard
											: classes.firstCard,
										activeKey === key ? classes.activeCard : ''
									])}>

										<Typography
											variant='body1'

										>
											{moment(date).format(DATE_FORMAT)}
										</Typography>
										<Typography
											variant='body2'
										>
											{title}
										</Typography>
									</Box>
								</LinkComponent>
							)}
							<LinkComponent to={NEWS_ROUTE} external_link className={classes.link}>
								<Box className={classNames([
									classes.card, classes.lastCard])}>
									<Typography
										className={classes.linkTitle}
										variant='body1'
										dangerouslySetInnerHTML={{ __html: t('main_page.see_all') }}
									/>
									<ArrowBtnComponent
										className={classes.linkArrowIcon}
									/>
								</Box>
							</LinkComponent>
							<LinkComponent
								to={NEWS_ROUTE}
								className={classes.buttonLink}
								external_link
							>
								<ButtonComponent
									title={t('btns.see_all_news')}
								/>
							</LinkComponent>
						</>
						: ''
				}
			</Container>
			{/* <HeroLineSvg
				className={classes.line}
			/> */}
		</Box>
	)
}


MainHeroImageComponent.propTypes = {
	loading: PropTypes.bool,
	info: PropTypes.array,
}
export default React.memo(MainHeroImageComponent);
