import React from 'react'
import {  Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import LargeCardComponent from './LargeCardComponent';
import { mobileScrollCont } from '../constants/csses';

const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: theme.spacing(5),
		...mobileScrollCont(theme),
		'& a': {
			textDecoration: 'none',
			color: theme.palette.secondary.main,
			transition: 'all 0.2s ease-in',
			'&:hover': {
				color: theme.palette.primary.main,
			},
		},

	}
}))

const CardComponent = ({ borderCard, to, src, title, btnTitle, description, primary, iconSrc, rowReverse, external_link }) => {
	const classes = useStyles();
	
	return (
		<Grid className={classes.container} container direction={rowReverse ? 'row-reverse' : 'row'}>
			<LargeCardComponent
				elevation={borderCard ? 4 : 2}
				src={src}
				title={title}
				external_link={external_link}
				to={to}
			/>
			<LargeCardComponent
				elevation={borderCard ? 4 : 2}
				iconSrc={iconSrc}
				primary={primary}
				external_link={external_link}
				title={title}
				to={to}
				description={description}
				btnTitle={btnTitle}
			/>
		</Grid>
	)
}


CardComponent.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	borderCard: PropTypes.bool,
	external_link: PropTypes.bool,
	btnTitle: PropTypes.string,
	to: PropTypes.string,
	src: PropTypes.string,
}
export default React.memo(CardComponent);