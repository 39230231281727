import React from 'react'
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';


const SkeletonComponent = ({ className}) => {


	return (
		<Paper elevation={3} className={className}>
			<Skeleton
			variant='rect'
			width='100%'
			height='100%'
			/>
		</Paper>
	)
}


SkeletonComponent.propTypes = {
	className: PropTypes.string,
}
export default React.memo(SkeletonComponent);