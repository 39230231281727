import React from 'react'
import { Box, Drawer, makeStyles, } from '@material-ui/core';
import LogoSvg from '../svgs/LogoSvg';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { FETCH_MENU_REDUCER } from '../reducers/MainReducer';
import LogoLinkComponent from './LogoLinkComponent';
import { MAIN_ROUTE } from '../constants/routes';
import BurgerMenuComponent from './BurgerMenuComponent';
import CityPagesComponent from './CityPagesComponent';
import loadable from '@loadable/component'
import preloadPages from '../functions/preloadPages';
const MenuItemComponent = loadable(() => import('./MenuItemComponent'))
const SubmenuComponent = loadable(() => import('./SubmenuComponent'))

const useStyles = makeStyles((theme) => ({
	header: {
		borderBottom: `1px solid ${theme.palette.gray.border}`,
		background: theme.palette.background.default,
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	headerBody: {
		...theme.custom.header,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
	},
	logo: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: 250,
		[theme.breakpoints.down('sm')]: {
			width: 180,
			
		}
	},
	drawerLogo: {
		height: 40,
	},

	linksBox: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	burgerBox: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	mobileBurger: {
		display: 'none',
		[theme.breakpoints.down('sm')]: {
			display: 'block'
		}
	},
	menuAndSubmenuBox: {
		position: 'relative',
		flex: 1,
	},
	menuBox: {
		display: 'flex',
		justifyContent: 'flex-end',
		flex: 1,
		position: 'relative',
		background: theme.palette.background.white,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		alignItems: 'center',
		zIndex: 10,
	},
	menu: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		}
	},
	drawerPaper: {
		minWidth: 250,
		padding: theme.spacing(4),
	},
	mobileBurgerBox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2),
	}
}))

const MainMenuComponent = (props) => {
	const classes = useStyles();
	const [showMobile, setShowMobile] = useState(false);
	const [showSubmenu, setShowSubmenu] = useState(false);
	const { data } = useSelector((state) => state.MainReducer);
	const dispatch = useDispatch();


	useEffect(() => {
		if (!data?.cityPages && !data?.menu)
			dispatch(FETCH_MENU_REDUCER())
			 // eslint-disable-next-line
	}, [dispatch])

	useEffect(() => {
		return () => {
			setShowSubmenu(false)
		}
	}, [])

	const handleToggleDrawer = useCallback(() => {
		setShowMobile(!showMobile)
	}, [showMobile])

	const handleToggleSubmenu = useCallback(() => {
		setShowSubmenu(!showSubmenu)
	}, [showSubmenu])

	const handlePreloadPages = useCallback(() => {
		preloadPages();
	}, [])

	return (
		<Box
			component='header'
			className={classes.header}
			onMouseOver={handlePreloadPages}
		>
			<Box className={classes.headerBody}>
				<LogoLinkComponent
					Logo={LogoSvg}
					to={MAIN_ROUTE}
					className={classes.logo}
				/>
				<Box className={classes.linksBox}>
					<Box className={classes.menuAndSubmenuBox}>
						<Box className={classes.menuBox}>
							<Box className={classes.menu}>
								{data?.menu?.length
									? data.menu.map((item, key) =>
										<MenuItemComponent
											to={item?.to}
											name={item?.title}
											external_link={item?.external_link}
											key={key}
										/>
									)
									: null}
							</Box>
							<BurgerMenuComponent
								active={showSubmenu}
								onClick={handleToggleSubmenu}
								classNameBox={classes.burgerBox}
							/>
							<BurgerMenuComponent
								onClick={handleToggleDrawer}
								classNameBox={classes.mobileBurger}
							/>
						</Box>
						<SubmenuComponent
							items={data?.pages}
							active={showSubmenu}
						/>
					</Box>
					<CityPagesComponent />
				</Box>
			</Box>
			<Drawer
				anchor={'right'}
				open={showMobile}
				onClose={handleToggleDrawer}
				classes={{
					paper: classes.drawerPaper
				}}
			>
				<Box className={classes.mobileBurgerBox}>
					<LogoLinkComponent
						Logo={LogoSvg}
						onClick={handleToggleDrawer}
						to={MAIN_ROUTE}
						className={classes.drawerLogo}
					/>
					<BurgerMenuComponent
						active
						onClick={handleToggleDrawer}
					/>
				</Box>
				{data?.menu?.length
					? data.menu.map((item, key) =>
						<MenuItemComponent
							to={item?.to}
							name={item?.title}
							external_link={item?.external_link}
							key={key}
						/>
					)
					: null}
				{data?.pages?.length
					? data.pages.map((item, key) =>
						<MenuItemComponent
							to={item?.to}
							name={item?.title}
							key={`1-${key}`}
						/>
					)
					: null}
			</Drawer>
		</Box>
	)
}

export default React.memo(MainMenuComponent);